import React from 'react';
import { Link } from 'react-router-dom';

import { setTitle, setMetaDescription, addStructuredData, removeStructuredData } from '../utilities';

// Background Graphics
import TopLeftGraphic from '../assets/graphics/getStarted/topLeftCube.svg';
import TopRightGraphic from '../assets/graphics/getStarted/topRightCubes.svg';

// Contact Form
import OnboardingContactForm from '../components/reusable/OnboardingContactFom';

// Components
import Question from '../components/reusable/Question';
import SchedulePhoneCall from '../components/reusable/SchedulePhoneCall';

// Whats Included Icons
import DiscountedShipping from '../assets/graphics/getStarted/whatsIncluded/discountedShipping.svg';
import NoPickPackFees from '../assets/graphics/getStarted/whatsIncluded/noPickPackFees.svg';
import DedicatedCustomerSupport from '../assets/graphics/getStarted/whatsIncluded/dedicatedCustomerSupport.svg';
import UnlimitedIntegrations from '../assets/graphics/getStarted/whatsIncluded/unlimitedIntegrations.svg';
import SpecialProjects from '../assets/graphics/getStarted/whatsIncluded/specialProjects.svg';
import RetailFulfillmentNetwork from '../assets/graphics/getStarted/whatsIncluded/retailFulfillmentNetwork.svg';
import NoMinimumOrderQuantities from '../assets/graphics/getStarted/whatsIncluded/noMinimumOrderQuantities.svg';
import CustomPackaging from '../assets/graphics/getStarted/whatsIncluded/customPackaging.svg';
import FlexibleOptions from '../assets/graphics/getStarted/whatsIncluded/flexibleOptions.svg';
import InventoryManagementSoftware from '../assets/graphics/getStarted/whatsIncluded/inventoryManagementSoftware.svg';

// Contact Icons
import ConnectOnWhatsApp from '../components/reusable/ConnectOnWhatsApp';
import { GET_STARTED_LINK } from '../Constants';

const titles = ["Get a FREE Custom Quote today", "Get Started"];
const description = "Get Connected with a dedicated customer support specialist to learn our process and get a free customized quote.";

const WHATS_APP_HREF = "https://wa.me/17732317510";

const FAQ_structured_data_id = "faq-structured";
const FAQ_QUESTIONS = [
    {
        question: "What does a fulfillment center do?",
        answer: "A fulfillment center takes care of all your warehousing and shipping needs. Fulfillment partners save their clients time and money so they can focus on their core business."
    },
    {
        question: "Does Librex Ship outside of the United States?",
        answer: "Yes, Librex ships orders to all countries. Gain advantage of discounted rates from our international shipping partners to get you the cheapest and quickest delivery."
    },
    {
        question: "Does Librex have customized packaging?",
        answer: "Yes, Librex lets the customer choose how their product gets packaged. Pick from any of our free packaging options or select your own. We can even design and print your items!"
    },
    {
        question: "How much does packaging cost?",
        answer: "All packing is FREE! At Librex, standard packaging comes included with fulfillment services."
    },
    {
        question: "Does Librex offer FBA prep services?",
        answer: "Yes, Librex has FBA-trained staff preparing your orders. Our accuracy ensures your product will arrive at Amazon warehouses in compliance with their standards."
    },
    {
        question: "Can I do Seller Fulfilled Prime?",
        answer: "Librex allows sellers to have Prime listed items without all of the extra fees. Reach all of your clients with standard 2-day shipping."
    },
    {
        question: "How can I get a quote?",
        answer: "Complete our form then we will get back to you in 4 hours or less. Want to skip the hassle of email? Call, Text, or WhatsApp us."
    },
    {
        question: "How does Librex do its shipping?",
        answer: "Librex receives orders in real-time, then picks, packs and ships the same day. Librex has many shipping partners for International and domestic shipping. Our software compares rates from all shipping carriers to ensure our customers get the fastest shipping options."
    },
    {
        question: "How can I view my account activity?",
        answer: "Librex has an online portal where sellers can view their shipments. Accounts get updated in real-time to ensure integrations are always up to date."
    },
    {
        question: "What happens when I get an order?",
        answer: "Orders get pulled into Librex's shipping software in real-time. From here, our warehouse team can view your orders and fulfill them. All orders received before 3 PM EST get pick packed and shipped the same day."
    },
    {
        question: "How does customer service work?",
        answer: "Each customer is assigned 2 dedicated customer service representatives. One representative is available for all your general questions software support, and another is dedicated to warehouse and inventory needs. This allows customers to get fast direct answers when they need them."
    },
    {
        question: "When should I use a third-party fulfillment partner?",
        answer: "Third-party fulfillment is Ideal for growing businesses and International sellers. Anyone who needs to scale infinitely, save on shipping costs and time needs an experienced fulfillment partner."
    },
    {
        question: "What happens when there is an order error?",
        answer: "Librex has 99.999% order fulfillment accuracy. If an error occurs during the fulfillment process, Librex will cover the full cost of fulfillment."
    },
    {
        question: "What if I have an issue with my account?",
        answer: "Your dedicated service representative is available to answer all your questions over email, phone call, text, and even Whatsapp!"
    },
    {
        question: "What is the average order turn around?",
        answer: "We ship all orders the same day if received before 12:30 PM. Amazon prep turn around is 36 hours."
    },
    {
        question: "How can I reach Librex directly?",
        answer: <p>Librex can be reached via <button className="btn btn-link p-0" data-bs-toggle="modal" data-bs-target="#schedulePhoneCallModal">phone call</button>, <Link to={`${GET_STARTED_LINK}#contact`}>email</Link>, text message, or <a href={WHATS_APP_HREF} target="_blank" rel="noopener noreferrer">WhatsApp</a>. Our staff works around the clock to answer your inquiries. The average response time is under 2 hours!</p>
    }
]

class GetStarted extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: window.innerWidth < 992
        }

        this.setOpen = this.setOpen.bind(this);
    }

    setOpen() {
        this.setState({
            open: window.innerWidth < 992
        });
    }

    componentDidMount() {
        // Set page title and meta description
        setTitle(titles);
        setMetaDescription(description);

        // Add FAQ structured data
        const FAQ_structured_data = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": FAQ_QUESTIONS.map( (qa) => {
                return {
                    "@type": "Question",
                    "name": qa.question,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": qa.answer
                    }
                }
            })
        }
        addStructuredData(FAQ_structured_data, FAQ_structured_data_id);

        // Scroll to the top of the page IF there isn't an ID attached to the URL
        if (window.location.toString().split('#').length === 1) {
            window.scrollTo(0, 0);
        }

        window.onresize = () => {
            this.setOpen();
        }
    }

    componentWillUnmount() {
        removeStructuredData(FAQ_structured_data_id);
        window.onresize = () => { }
    }

    render() {
        return (
            <main className="get-started mb-5" id="main" >
                <div className="container-fluid px-0 pb-lg-5 position-relative">
                    <img src={TopLeftGraphic} className="img-fluid d-none d-lg-block" alt="" style={{ position: 'absolute', top: '50px', left: '0px', zIndex: '-100' }} />
                    <img src={TopRightGraphic} alt="" className="d-none d-md-block" style={{ position: 'absolute', top: '0px', right: '0px', zIndex: '-100', maxWidth: '50vw', height: 'auto' }} />


                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 col-lg-6 pt-5 order-2 order-lg-1" id="whatsIncluded">
                                <h5 className="mb-3 mb-lg-5">What's Included</h5>

                                <div className="mx-auto mx-lg-0 include-list">
                                    <div className="single-included">
                                        <img src={DiscountedShipping} className="img-fluid" alt="" />
                                        <p>Discounted <span className="text-dark-blue fw-bold">2-day</span><br />shipping</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={NoPickPackFees} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">No</span> pick pack fees<br />or extra charges</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={DedicatedCustomerSupport} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">Dedicated</span> customer<br />support specialist</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={UnlimitedIntegrations} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">Unlimited</span> selling<br />channel integrations</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={SpecialProjects} className="img-fluid" alt="" />
                                        <p>Special projects to<br />take care of any<br />inventory needs</p>
                                    </div>

                                    <div className="single-included">
                                        <img src={RetailFulfillmentNetwork} className="img-fluid" alt="" />
                                        <p>Retail Fulfillment<br /><span className="text-dark-blue fw-bold">network</span> capabilities</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={NoMinimumOrderQuantities} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">No</span> minimum<br />order quantities</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={CustomPackaging} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">Custom</span> packaging<br />options</p>
                                    </div>
                                    <div className="single-included">
                                        <img src={FlexibleOptions} className="img-fluid" alt="" />
                                        <p>Flexible options for<br /><span className="text-dark-blue fw-bold">all businesses</span></p>
                                    </div>
                                    <div className="single-included">
                                        <img src={InventoryManagementSoftware} className="img-fluid" alt="" />
                                        <p><span className="text-dark-blue fw-bold">Free</span> inventory<br />management<br />software</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 col-lg-6 d-flex justify-content-center mt-5 mt-lg-0 ps-lg-5 pt-lg-5 order-1 order-lg-2">
                                <OnboardingContactForm always_small/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h1 className="mt-5 mb-3 text-break">Want to skip the<br />hassle of email?</h1>
                                <div className="hassle-free mt-5">

                                    <SchedulePhoneCall className="me-3 me-lg-5" />

                                    <ConnectOnWhatsApp />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-5" id="faq">
                            <div className="col-12">
                                <h1 className="text-dark-bright-purple-gradient mb-3">FAQ</h1>

                                <div className="accordion faq" id="faqAccordion">
                                    {FAQ_QUESTIONS.map((el, i) => {
                                        return (
                                            <Question question={el.question} answer={el.answer} i={i} open={this.state.open} key={i} />
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default GetStarted;