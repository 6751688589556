import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

function LibrexButton(props){
    const {color, className, to, text, href, ...remainingProps} = props;
    const classes = `btn btn-outline-${color} ${className}`;
    const role = "button";
    let buttonProps = {className: classes, role, ...remainingProps};

    if( to ){
        buttonProps.to = to;
        return <Link {...buttonProps}>{text}</Link>
    }

    else if( href ){
        buttonProps.href = href;
        return <a {...buttonProps}>{text}</a>
    }

    else{
        return (
            <button {...buttonProps}>{text}</button>
        )
    }
}

LibrexButton.defaultProps = {
    color: "primary",
    className: ""
}

LibrexButton.propTypes = {
    // A color string from the Bootstrap theme color variables
    color: PropTypes.string,
    // Additional classes to apply to the button 
    className: PropTypes.string,
    // Link to redirect to on button press. Iff passed button will render as an achor tag
    to: PropTypes.string
}

export default LibrexButton;