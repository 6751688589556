/**
 * 
 * @param {array} titles 
 */
export const setTitle = (titles) => {
    let clean_titles = Array.isArray(titles) ? titles : [titles];
    if (clean_titles[0] !== "Librex Fulfillment"){
        clean_titles.unshift("Librex Fulfillment");
    }
    document.title = clean_titles.join(" | ");
}

export const setMetaDescription = (description) => {
    document.querySelector('meta[name="description"]').setAttribute("content", description);
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const addStructuredData = (structured_data, data_id) => {
    const scructured_data_element = document.createElement('script');
    scructured_data_element.type = "application/ld+json";
    scructured_data_element.id = data_id;
    scructured_data_element.textContent = JSON.stringify(structured_data);
    document.head.appendChild(scructured_data_element);
}

export const removeStructuredData = (data_id) => {
    document.getElementById(data_id).remove();
}