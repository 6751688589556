import React, { useState } from "react";
import Axios from 'axios';
// components
import LibrexButton from "./LibrexButton";
import SchedulePhoneCall from "./SchedulePhoneCall";
// icons
import CustomerIcon from "../../assets/graphics/contactForm/customer-icon.svg";
import EmailIcon from "../../assets/graphics/contactForm/email-icon.svg";
import PhoneIcon from "../../assets/graphics/contactForm/phone-icon.svg";
// scss
import "../../assets/scss/_onboardingContactForm.scss";

const SEND_MAIL_URL = window.location.protocol + '//' + window.location.hostname + '/api/v1/sendmail';

// Event snippet for Contact Form Submitted conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button

function gtag_report_conversion(url) {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    // eslint-disable-next-line no-undef
    gtag('event', 'conversion', {
        'send_to': 'AW-803920443/wg8jCOnC66MCELu0q_8C',
        'event_callback': callback
    });
    return false;
}

function OnboardingContactForm(props) {
    const [alert, setAlert] = useState({ show: false, color: "", message: "" });
    const [submitted, setSubmitted] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();
        // Prevent multiple submissions
        setSubmitted(true);
        gtag_report_conversion();

        const firstName = document.querySelector("input[name='firstName']").value;
        const lastName = document.querySelector("input[name='lastName']").value;
        const email = document.querySelector("input[name='email']").value;
        const countryCode = document.querySelector("input[name='countryCode']").value;
        const phone = document.querySelector("input[name='phone']").value;

        const options = {
            headers: { 'Content-Type': 'application/json' }
        };
        const reqBody = { firstName, lastName, email, phone, countryCode };

        await Axios.post(SEND_MAIL_URL, reqBody, options).then((res) => {
            setAlert({
                show: true,
                color: 'success',
                message: 'A Librex Fulfillment Representative will contact you shortly!'
            });
        }).catch((e) => {
            setAlert({
                show: true,
                color: 'danger',
                message: 'There was an error sending your contact request. Please try again.'
            });
            // Allow anoother submission on failure
            setSubmitted(false);
        });

    }

    function Alert() {
        if (alert.show === false) {
            return <></>
        }
        return (
            <div className="col-12">
                <div className={`alert alert-${alert.color}`} role="alert">{alert.message}</div>
            </div>
        )
    }
    return (
        <form className={`container-lg onboarding-contact-form p-3 p-md-5${props.always_small ? " always-small" : ""}`} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12">
                    <h2 className={`text-primary ${props.always_small ? "mb-3" : "mb-lg-5"}`}>Get Started Today</h2>
                </div>
                <Alert />
                <div className="col-12">
                    <div className={`d-flex flex-column${!props.always_small ? " flex-md-row justify-content-md-between" : ""}`}>

                        <div className="half-1">
                            <p className="my-3">Get connected with a dedicated customer support representative to answer all of your questions and create your custom fulfillment quote.</p>
                            <SchedulePhoneCall className="mt-auto" no_break/>
                        </div>

                        <div className="onboarding-contact-form-section-break"><span className="onboarding-contact-form-divider"></span>OR<span className="onboarding-contact-form-divider"></span></div>


                        <div className="half-2">
                            <div className="hstack gap-3">
                                <img src={CustomerIcon} alt="" />
                                <input className="form-control" type="text" placeholder="First name" aria-label="First name" name="firstName" required />
                                <input className="form-control" type="text" placeholder="Last name" aria-label="Last name" name="lastName" required />
                            </div>
                            <div className="hstack gap-3">
                                <img src={EmailIcon} alt="" />
                                <input className="form-control" type="text" placeholder="Email" aria-label="Email" name="email" required />
                            </div>
                            <div className="hstack gap-3">
                                <img src={PhoneIcon} alt="" />
                                <input className="form-control" type="text" placeholder="Country code" aria-label="Country code" name="countryCode" style={{ maxWidth: "140px" }} />
                                <input className="form-control" type="text" placeholder="Phone number" aria-label="Phone number" name="phone" required />
                            </div>

                            <LibrexButton color="secondary" text="Connect with a Representative" type="submit" disabled={submitted} className="mt-3" />
                        </div>

                    </div>
                </div>
            </div>
        </form>
    );
}

export default OnboardingContactForm;