import React, { useEffect } from "react";
import { SECTION_CONTENT } from "../content/TermsOfServiceContent";

function renderSubSection(section_number, subsection_number, subsection) {
    return (
        <div className="col-12 mt-3">
            <h4><span className="text-grey">{section_number}.{subsection_number}</span> {subsection.title}</h4>
            {subsection.content}
        </div>
    )
}

function renderSection(number, section) {
    return (
        <div className="row mt-5">
            <div className="col-12 mt-3">
                <h2><span className="text-primary">{number}</span> {section.title}</h2>
            </div>
            {section.content.map((subsection, i) => renderSubSection(number, i + 1, subsection))}
        </div>
    )
}

function TermsOfService(props) {
    useEffect(() => {
        // Scroll to the top of the page IF there isn't an ID attached to the URL
        if (window.location.toString().split('#').length === 1) {
            window.scrollTo(0, 0);
        }
    }, [])
    return (
        <main className="container my-5 py-5">
            <div className="row">
                <div className="col-12">
                    <p>LIBREX FULFILLMENT</p>
                    <p>THIS SERVICE AGREEMENT (“Agreement”) is entered between you the (“Customer”) and us (“Librex Fulfillment” or “Librex”). This document outlines what is expected from both parties in this business relationship. By using Librex Fulfillment's services, you are agreeing to the terms of this Agreement.</p>
                </div>
                {SECTION_CONTENT.map((section, i) => { return renderSection(i + 1, section) })}

                <div className="col-12 my-5">
                    <p>Librex Fulfillment may change or revise this Agreement at our discretion. If any change or revision to this Agreement is not acceptable to you, your only remedy is to stop using our Services and send a cancellation email to <a href="mailto:support@librexfulfillment.com">support@librexfulfillment.com</a> Otherwise, you will be bound by the changed or revised terms. Librex Fulfillment may change or revise this Agreement from time to time by providing ten (10) days prior notice either by emailing the email address associated with your account or by posting a notice on the LibrexGo Dashboard. You can review the most current version of this Agreement at any time here or by logging into your account. Your use of the Services ten (10) days after this Notice shall constitute full acceptance of the revised or changed terms.</p>
                </div>
            </div>
        </main>
    );
}

export default TermsOfService;