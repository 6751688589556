import { Link } from 'react-router-dom';

// Fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ArrowLink = (props) => {
    return (
        <Link className={props.classes} to={props.link}>{props.text}<FontAwesomeIcon icon={faAngleRight} className="ms-2" /></Link>
    );
}

export default ArrowLink;