import ScheduleCall from '../../assets/graphics/home/inquireNow/scheduleCall.svg';
const SchedulePhoneCallWithIcon = (props) => {
    return (
        <div className="card text-center border-0" role="button" data-bs-toggle="modal" data-bs-target="#schedulePhoneCallModal">
            <img src={ScheduleCall} className="card-img-top" alt="Schedule a phone call with a Librex representative" />
            <div className="card-body">
                <div className="card-text">
                    <button className="btn btn-link purple-link large-link text-center stretched-link" >{props.text ? props.text :
                        <span>{props.line1}<br />{props.line2}</span>}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SchedulePhoneCallWithIcon;