function LogoWhite() {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0H3ZM4.71054 7.11994L4.68181 7.10346L10.2996 4.00001L15.9031 7.1322L15.8887 7.13991L15.8744 13.3679L10.2991 16.4701L10.2996 16.5L10.2726 16.4849L10.2709 16.4858L10.2709 16.4839L4.68181 13.3535L4.71054 7.11994ZM10.2278 7.34772L12.8214 8.78065L12.814 8.78461V11.6869L10.2152 13.1515L7.64159 11.7012V8.79886L7.61473 8.78346L10.2278 7.34772Z" fill="white" />
            </svg>
            <span className="ms-2" style={{color: "#fff"}}><strong>Librex</strong> Fulfillment</span>
        </>
    );
}

export default LogoWhite;