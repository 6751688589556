import React from 'react';
import { Link } from 'react-router-dom';
import {SOFTWARE_LINK, GET_STARTED_LINK} from "../Constants"

import { setTitle, setMetaDescription } from '../utilities';

// Sections & Components
import FeaturesWeOffer from '../sections/FeaturesWeOffer';
import TrustpilotReview from '../components/reusable/TrustpilotReview';
import ArrowLink from '../components/reusable/ArrowLink';
import SchedulePhoneCallWithIcon from '../components/reusable/SchedulePhoneCallWithIcon';

// Graphics
import HomeGraphic from '../assets/graphics/home/homeBackground.svg';
import HomeGraphicMobile from '../assets/graphics/home/homeBackgroundMobile.svg';
import SendAndConnect from '../assets/graphics/home/howItWorks/sendAndConnect.svg';
import Store from '../assets/graphics/home/howItWorks/store.svg';
import Ship from '../assets/graphics/home/howItWorks/ship.svg';
import ReviewsBackground from '../assets/graphics/home/reviewsBackground.svg';
import Community from '../assets/graphics/home/communityCubes.svg';
import SliderOption from '../assets/graphics/home/makeItSimple/sliderOption.svg';

// Inquite now icons
import GetStarted from '../assets/graphics/home/inquireNow/getStarted.svg';
import LiveChat from '../assets/graphics/home/inquireNow/liveChat.svg';

// Integrations image - png
import AmazonPNG from '../assets/icons/integrations/amazon.png';
import EbayPNG from '../assets/icons/integrations/ebay.png';
import ShopifyPNG from '../assets/icons/integrations/shopify.png';
import SquarespacePNG from '../assets/icons/integrations/squarespace.png';
import WalmartPNG from '../assets/icons/integrations/walmart.png';
import WixPNG from '../assets/icons/integrations/wix.png';
import SellBritePNG from '../assets/icons/integrations/sellbrite.png'

// Integrations image - webp
import AmazonWEBP from '../assets/icons/integrations/amazon.webp';
import EbayWEBP from '../assets/icons/integrations/ebay.webp';
import ShopifyWEBP from '../assets/icons/integrations/shopify.webp';
import SquarespaceWEBP from '../assets/icons/integrations/squarespace.webp';
import WalmartWEBP from '../assets/icons/integrations/walmart.webp';
import WixWEBP from '../assets/icons/integrations/wix.webp';

// Integrations image - svg
import EtsySVG from '../assets/icons/integrations/etsy.svg';
import WooCommerce from '../assets/icons/integrations/wooCommerce.svg';

const titles = ["Fulfillment Center", "eCommerce Fulfillment Solutions"];
const description = "Librex is not your conventional eCommerce logistics partner (3PL). We make the fulfillment process painless and easy to understand using our online portal. Paired with our trustworthy customer service and flat rate shipping fees there are never any surprise costs.";
const SLIDER_KNOB_ID = 'sliderKnob'
const REVIEWS_BACKGROUND = `url(${ReviewsBackground})`;
const NUM_MAKE_IT_SIMPLE_SLIDE = 4;
const SLIDER_INTERVAL = 3000;
const REVIEWS = [
    {
        name: "John Tran",
        date: "Jan 11, 2021",
        title: "Affordable & Convenient",
        body: "I definitely recommend this 3PL company if you are looking to find a warehouse to store your products in safe hands. These guys go above and beyond to help their clients with their needs and will guide you every step of the way. They also have a website that makes it super easy to replenish your inventory (super simple steps btw). 100% recommend"
    },
    {
        name: "Michael Jordan",
        date: "Dec 28, 2020",
        title: "The best 3PL I've used",
        body: "Librex has been a savior for my business. With the boom in online sales this year that put a massive strain on the logistics industry, I was having severe problems with my previous 3PL provider. I switched to Librex and they simply do what they say they will, on time and correctly. Thanks guys!"
    },
    {
        name: "Anthony Dickson",
        date: "Dec 21, 2020",
        title: "Librex is Leading the Way",
        body: "Librex has manage our fulfilment services for the last several months. We had to find an alternate due to under performance of our previous vendor. They have been excellent with their responsiveness, communication, and level of service. I can't say enough about their attention to detail and suggestions to improve our operations."
    },
    {
        name: "Rochak G",
        date: "Dec 16, 2020",
        title: "Great to work with.",
        body: "Been with Librex for over a year now. Good prices and great support team. Small issues sometimes here and there but It would be unrealistic to expect 100% flawlessness. How Librex makes up for it is by having a fast and responsive customer support team that are always there for you. They rectify the situations swiftly to the best of there abilities."
    }
];

function getSliderAt(position) {
    return document.querySelector(`[data-slider-pos='${position}']`);
}

const windowIsDesktop = () => { return window.innerWidth >= 768 }

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.backgroundRef = React.createRef();
        this.mobileBackgroundRef = React.createRef();

        this.state = {
            isLoaded: false,
            currentSlide: 0
        };

        this.alignContent = this.alignContent.bind(this);
        this.loaded = this.loaded.bind(this);

        this.nextSlide = this.nextSlide.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.moveSliderTo = this.moveSliderTo.bind(this);
        this.moveSliderContentUp = this.moveSliderContentUp.bind(this);
        this.moveSliderContentDown = this.moveSliderContentDown.bind(this);
    }

    loaded(){
        this.setState({
            isLoaded: true
        })
    }

    startSliderTimer() {
        this.simpleTimerInterval = setInterval(this.nextSlide, SLIDER_INTERVAL);
    }

    resetSliderTimer() {
        this.stopSliderTimer();
        this.startSliderTimer();
    }

    stopSliderTimer() {
        try{
            clearInterval(this.simpleTimerInterval);
        }catch (e){

        }
    }
    sliderDragEndHandler(event) {
        event.stopPropagation();
        document.getElementById(SLIDER_KNOB_ID).style.opacity = "";
    } 
    
    sliderDragStartHandler(event) {
        event.stopPropagation();
        document.getElementById(SLIDER_KNOB_ID).style.opacity = .5;
    }

    sliderDragOverHandler(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    sliderDropHandler(event) {
        if (event.target.classList.contains('slider')) {
            const targetPos = parseInt(event.target.getAttribute("data-slider-pos"));
            this.resetSliderTimer();
            this.moveSliderTo(targetPos);
        }
    }


    /**
     * Aligns content based on the background image size and screen width.
     * Content aligned includes:
     * - make it simple
     */
    alignContent() {
        const simpleTop = windowIsDesktop() ? this.backgroundRef.current.clientHeight * .79 : this.mobileBackgroundRef.current.clientHeight;
        const simpleLeft = windowIsDesktop() ? window.innerWidth * .51 : 0;

        this.setState({
            simpleLeft: simpleLeft,
            simpleTop: simpleTop
        });
    }

    nextSlide() {
        const nextSlidePosition = this.state.currentSlide + 1 >= NUM_MAKE_IT_SIMPLE_SLIDE ? 0 : this.state.currentSlide + 1
        this.moveSliderTo(nextSlidePosition)
    }

    prevSlide() {
        const prevSlidePosition = this.state.currentSlide - 1 < 0 ? NUM_MAKE_IT_SIMPLE_SLIDE - 1 : this.state.currentSlide - 1;
        this.moveSliderTo(prevSlidePosition);
    }

    moveSliderTo(position) {
        const sliderKnob = document.getElementById(SLIDER_KNOB_ID);
        const targetPos = getSliderAt(position);

        let newTop;
        try {
            const halfKnobHeight = Math.floor(parseInt(sliderKnob.clientHeight) / 2);
            const halfTargetHeight = Math.floor(parseInt(targetPos.clientHeight) / 2);
            newTop = parseInt(targetPos.offsetTop) + halfTargetHeight - halfKnobHeight;
        } catch (e) {
            newTop = 0;
        }

        sliderKnob.style.top = `${newTop}px`;
        if (position > this.state.currentSlide || (position === 0 && this.state.currentSlide === NUM_MAKE_IT_SIMPLE_SLIDE - 1)) {
            this.moveSliderContentUp(position);
        } else {
            this.moveSliderContentDown(position);
        }

        this.setState({
            currentSlide: position
        });
    }

    moveSliderContentUp(toSlide) {
        const from = document.querySelector(`[data-slide='${this.state.currentSlide}'`);
        const to = document.querySelector(`[data-slide='${toSlide}'`);
        from.classList.remove('active');
        from.classList.add('exit-down');

        to.classList.add('exit-up');
        to.classList.add('active');
        to.classList.remove('exit-up');
    }

    moveSliderContentDown(toSlide) {
        const from = document.querySelector(`[data-slide='${this.state.currentSlide}'`);
        const to = document.querySelector(`[data-slide='${toSlide}'`);
        from.classList.remove('active');
        from.classList.add('exit-up');

        to.classList.add('exit-down');
        to.classList.add('active');
        to.classList.remove('exit-down');
    }


    componentDidMount() {
        // Set Title and meta description
        setTitle(titles);
        setMetaDescription(description)
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        // Align content with every page resize
        window.onresize = () => {
            this.alignContent()
        }

        this.startSliderTimer();
    }

    componentWillUnmount() {
        // Clean up onload and onresize functions to prevent the program entering a bad state
        // this.backgroundRef.current.onload = () => { }
        // this.mobileBackgroundRef.current.onload = () => { }

        window.onresize = () => { }
        clearInterval(this.simpleTimerInterval);
    }


    render() {
        return (
            <main className={`home ${ this.state.isLoaded ? '' : ' invisible'}`} id="main">

                <div className="container-fluid px-0 pb-lg-5 position-relative">

                    <div className="row">
                        <div className="col-12">
                            <div className="background" id="home-background">
                                <img src={HomeGraphic} alt="Graphic showing Librex's operations" className="d-none d-md-block position-absolute" style={{top: 0}}ref={this.backgroundRef}  onLoad={() => {this.alignContent(); this.loaded()}}/>
                                <img src={HomeGraphicMobile} alt="Graphic showing Librex's operations" className="d-md-none" ref={this.mobileBackgroundRef}  onLoad={() => {this.alignContent(); this.loaded()}}/>
                            </div>
                        </div>
                    </div>

                    <div className="container-md home-info">
                        <div className="row">
                            <div className="col-12 d-flex flex-column pt-md-5 d-none d-md-block">
                                <h1 className="mt-2 text-white">Store · Prep · Pack · Ship</h1>
                                <h3 className="mb-3 text-white" style={{fontWeight: "300"}}>Trusted by Online Sellers Worldwide</h3>
                                <ArrowLink classes="h2 tertiary-link xlarge-link me-auto position-relative" link={GET_STARTED_LINK} text="GO FOR IT" />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container-md">

                    <div className="row">
                        <div className="col-12 make-it-simple py-3 mb-5 py-lg-0 mb-lg-0">

                            <div id='make-it-simple' className={`${this.state.simpleLeft === -1 ? 'd-none' : ''}`} style={{ left: this.state.simpleLeft, top: this.state.simpleTop }}>
                                <div className="d-flex flex-column justify-content-between flex-grow-1">
                                    <p>We make it <strong className="text-cool-blue-gradient">simple</strong>, and take care of the<br />tough parts for you with:</p>
                                    <div id="carousel">
                                        <p className="part active" data-slide='0'>Same Day Shipping</p>
                                        <p className="part" data-slide='1'>World Class<br />Customer Service</p>
                                        <p className="part" data-slide='2'>No Minimums</p>
                                        <p className="part" data-slide='3'>Flat Rate Shipping</p>
                                    </div>
                                </div>
                                <div id="slideBarContainer">
                                    <div id="slideBar">
                                        <div 
                                            id={SLIDER_KNOB_ID} 
                                            style={{ top: this.state.sliderTop, cursor: 'pointer' }} 
                                            draggable={true} 
                                            onDragStart={(event) => { this.sliderDragStartHandler(event)} }
                                            onDragEnd={(event) => { this.sliderDragEndHandler(event)} }
                                        ></div>
                                    </div>
                                    <div id="sliders" className="d-flex flex-column justify-content-between py-2">    
                                        <img src={SliderOption} 
                                            alt="" 
                                            className="slider"
                                            style={{marginTop: '-1.5rem'}}
                                            data-slider-pos="0"
                                            onClick={() => { this.moveSliderTo(0); this.resetSliderTimer(); }}
                                            onDragOver={(event) => { this.sliderDragOverHandler(event) }}
                                            onDrop={(event) => { this.sliderDropHandler(event) }}
                                        />
                                        <img src={SliderOption}
                                            alt="" 
                                            className="slider"
                                            data-slider-pos='1'
                                            onClick={() => { this.moveSliderTo(1); this.resetSliderTimer(); }}
                                            onDragOver={(event) => { this.sliderDragOverHandler(event) }}
                                            onDrop={(event) => { this.sliderDropHandler(event) }}
                                        />

                                        <img src={SliderOption}
                                            alt=""
                                            className="slider"
                                            data-slider-pos='2'
                                            onClick={() => { this.moveSliderTo(2); this.resetSliderTimer(); }}
                                            onDragOver={(event) => { this.sliderDragOverHandler(event) }}
                                            onDrop={(event) => { this.sliderDropHandler(event) }}
                                        />
                                        <img src={SliderOption} 
                                            alt="" 
                                            className="slider"
                                            data-slider-pos='3'
                                            onClick={() => { this.moveSliderTo(3); this.resetSliderTimer(); }}
                                            onDragOver={(event) => { this.sliderDragOverHandler(event) }}
                                            onDrop={(event) => { this.sliderDropHandler(event) }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-3 mb-lg-5">
                            <h5>How it works</h5>
                            <p style={{ maxWidth: '550px' }}>Librex is not your conventional Third Party Logistics partner (3PL). We strive to make the fulfillment process painless and easy to understand. With our trustworthy customer service and flat rate shipping fees there are never any surprise costs.</p>
                            <p>We take care of your <strong className="text-cool-blue-gradient">inventory</strong> <br className="d-md-none" />in <strong className="text-cool-blue-gradient">three</strong> easy steps:</p>
                        </div>

                        <div className="col-12 mb-5 steps">

                            <div className="step">
                                <div className="d-flex align-items-center order-1 order-lg-2 my-4 mb-lg-0">
                                    <div className="circle circle-large bg-bright-purple me-3">1</div>
                                    <h2 className="d-inline-block">Send &amp; <br className="d-lg-none" />Connect</h2>
                                </div>
                                <div className="d-flex align-items-center order-2 order-lg-1">
                                    <svg width="3" height="143" viewBox="0 0 3 143" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-lg-none" style={{ margin: '0px 30px' }}>
                                        <line x1="1.5" y1="1.5" x2="1.50001" y2="141.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                    </svg>
                                    <img src={SendAndConnect} className="img-fluid" alt="Send your inventory to Librex and connect to our software." />
                                </div>
                            </div>

                            <svg className="d-none d-lg-block svg-1" width="143" height="3" viewBox="0 0 143 3" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'relative', bottom: '30px' }}>
                                <line x1="141.5" y1="1.5" x2="1.5" y2="1.49999" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                            </svg>


                            <div className="step">
                                <div className="d-flex align-items-center order-1 order-lg-2 my-4 mb-lg-0">
                                    <div className="circle circle-large bg-bright-purple me-3">2</div>
                                    <h2 className="d-inline-block">Store</h2>
                                </div>
                                <div className="d-flex align-items-center order-2 order-lg-1">
                                    <svg width="3" height="143" viewBox="0 0 3 143" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-lg-none" style={{ margin: '0px 30px' }}>
                                        <line x1="1.5" y1="1.5" x2="1.50001" y2="141.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                    </svg>
                                    <img src={Store} className="img-fluid" alt="Librex stores your inventory." />
                                </div>
                            </div>

                            <svg className="d-none d-lg-block" width="226" height="3" viewBox="0 0 226 3" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: 'relative', bottom: '30px', left: '-100px' }}>
                                <line x1="224.5" y1="1.5" x2="1.5" y2="1.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                            </svg>


                            <div className="step">
                                <div className="d-flex align-items-center order-1 order-lg-2 my-4 mb-lg-0">
                                    <div className="circle circle-large bg-bright-purple me-3">3</div>
                                    <h2 className="d-inline-block">Ship</h2>
                                </div>
                                <div className="d-flex align-items-center order-2 order-lg-1">
                                    <img src={Ship} className="img-fluid ship" alt="Librex ships your orders when they're received from our software." />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <FeaturesWeOffer title="What comes with partnership" />

                <div className="container-fluid bg-white-gradient py">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12">
                                <h5>Integrations</h5>
                                <p className="d-lg-none mt-4">Librex offers unlimited selling integrations for all accounts via custom API’s to top ecommerce platforms. We can integrate them all!</p>
                            </div>

                            <div className="col-12 col-lg-7">
                                <div className="d-flex flex-wrap justify-content-between align-items-end integrations">
                                    <div>
                                        <picture>
                                            <source srcSet={AmazonWEBP} type="image/webp" />
                                            <source srcSet={AmazonPNG} type="image/png" />
                                            <img src={AmazonPNG} className="img-fluid" alt="Integrate Amazon with Librex GO" />
                                        </picture>
                                        <p>Amazon <span>Marketplace</span></p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={EbayWEBP} type="image/webp" />
                                            <source srcSet={EbayPNG} type="image/png" />
                                            <img src={EbayPNG} className="img-fluid" alt="Integrate Ebay with Librex GO" />
                                        </picture>
                                        <p>Ebay</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={ShopifyWEBP} type="image/webp" />
                                            <source srcSet={ShopifyPNG} type="image/png" />
                                            <img src={ShopifyPNG} className="img-fluid" alt="Integrate Shopify with Librex GO" />
                                        </picture>
                                        <p>Shopify</p>
                                    </div>

                                    <div>
                                        <img src={EtsySVG} className="img-fluid" alt="Integrate Etsy with Librex GO" />
                                        <p>Etsy</p>
                                    </div>

                                    <div>
                                        <img src={WooCommerce} className="img-fluid" alt="Integrate WooCommerce with Librex GO" />
                                        <p>WooCommerce</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={WixWEBP} type="image/webp" />
                                            <source srcSet={WixPNG} type="image/png" />
                                            <img src={WixPNG} className="img-fluid" alt="Integrate Wix with Librex GO" />
                                        </picture>
                                        <p>Wix</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={WalmartWEBP} type="image/webp" />
                                            <source srcSet={WalmartPNG} type="image/png" />
                                            <img src={WalmartPNG} className="img-fluid" alt="Integrate Walmart with Librex GO" />
                                        </picture>
                                        <p>Walmart</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={SellBritePNG} type="image/png" />
                                            <img src={SellBritePNG} className="img-fluid" alt="Integrate Sellbrite with Librex GO" />
                                        </picture>
                                        <p>Sellbrite</p>
                                    </div>

                                    <div className="hanger">
                                        <picture>
                                            <source srcSet={SquarespaceWEBP} type="image/webp" />
                                            <source srcSet={SquarespacePNG} type="image/png" />
                                            <img src={SquarespacePNG} className="img-fluid" alt="Integrate Squarespace with Librex GO" />
                                        </picture>
                                        <p>Squarespace</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-5 d-flex">
                                <div className="vertical-divider me-5 d-none d-lg-block">&nbsp;</div>
                                <div className="d-flex flex-column justify-content-around mt-5 mt-lg-0">
                                    <p className="d-none d-lg-block">We offer integrations with all of the top eCommerce platforms.</p>

                                    <p><Link to={`${SOFTWARE_LINK}#integrations`} className="purple-link large-link">SEE ALL INTEGRATIONS</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-white-gradient py inquire-now">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12">
                                <h5>Inquire Now</h5>
                            </div>
                            <div className="col-12 mt-3 mt-md-5">
                                <div className="d-flex flex-column flex-md-row justify-content-around align-items-md-end">

                                    <div className="card text-center border-0 mt-3 mt-md-0">
                                        <img src={LiveChat} className="card-img-top" alt="Start a live chat with a Librex representative" />
                                        <div className="card-body">
                                            <div className="card-text">
                                                <button className="btn btn-link purple-link large-link text-center stretched-link" onClick={() => { document.getElementById('zsiq_float').click() }}>Live Chat</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card text-center border-0 my-5 my-md-0">
                                        <img src={GetStarted} className="card-img-top" alt="Get started with Librex" />
                                        <div className="card-body">
                                            <div className="card-text">
                                                <Link className="purple-link large-link text-center stretched-link" to={GET_STARTED_LINK}>Get Started</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <SchedulePhoneCallWithIcon text="Schedule A Phone Call" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid py-5 trustpilot px-0">
                    <div className="background" style={{ backgroundImage: REVIEWS_BACKGROUND }}>

                    </div>
                    <div className="container-md content">
                        <div className="row">
                            <div className="col-12">
                                <h5>Reviews from Trustpilot</h5>
                                <p>Don't believe us? Hear it first from <br className="d-lg-none" />our customers.</p>
                            </div>
                            <div className="col-12 d-flex flex-wrap justify-content-center">
                                {REVIEWS.map((review, i) => {
                                    return (
                                        <TrustpilotReview name={review.name} location="US" reviewDate={review.date} reviewTitle={review.title} reviewBody={review.body} key={`review-${i}`} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-5 community">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <div>
                                    <p className="h1">Join the <br className="d-lg-none" />community of<br />hundreds of sellers <br className="d-lg-none" />just like you.</p>
                                    <ArrowLink classes="purple-link xlarge-link mt-5 d-block" link={GET_STARTED_LINK} text="GO FOR IT" />
                                </div>
                                <img src={Community} alt="" className="d-none d-md-block" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Home;