import LibrexButton from "./LibrexButton";

const SchedulePhoneCall = (props) => {
    const {no_break, ...buton_props} = props
    const text = no_break ? "Schedule a Free Phone Call Now" : <>Schedule a Free<br/>Phone Call Now</>
    return (
        <LibrexButton text={text} data-bs-toggle="modal" data-bs-target="#schedulePhoneCallModal" {...buton_props} />
    )
}

export default SchedulePhoneCall;