
import React from "react";

import { setTitle, setMetaDescription } from '../utilities';

// Components
import ArrowLink from "../components/reusable/ArrowLink";
import HalfHeightPurpleGradient from "../components/reusable/HalfHeightPurpleGradient";

// Contact Form
import OnboardingContactForm from "../components/reusable/OnboardingContactFom";

// Decorative graphics
import TopRightCubes from '../assets/graphics/about/top-right-cubes.svg';
import LeftSideCube from '../assets/graphics/about/left-side-cube.svg';
import LocationsMap from '../assets/graphics/about/location-map.svg';

// Info graphics
import ResponseTime from '../assets/graphics/about/info-graphics/2hrs.svg';
import Customers from '../assets/graphics/about/info-graphics/customers.svg';
import SalesIncrease from '../assets/graphics/about/info-graphics/sales-increase.svg';
import Transactions from '../assets/graphics/about/info-graphics/transactions.svg';

// Timeline graphics
import LivingRoom from '../assets/graphics/about/timeline/livingroom.svg';
import Inc from '../assets/graphics/about/timeline/inc.svg';
import SmallWarehouse from '../assets/graphics/about/timeline/small-warehouse.svg';
import MultiWarehouse from '../assets/graphics/about/timeline/mult-small-warehouses.svg';
import Software from '../assets/graphics/about/timeline/software.svg';
import BigWarehouse from '../assets/graphics/about/timeline/big-warehouse.svg';
import { GET_STARTED_LINK } from "../Constants";

const titles = ["About Us"];
const description = "Founded in 2016, Librex has always stuck by the principle that delivering your product should be simple and inexpensive. Retail giants setting customer expectations for 1-2 day shipping has made this goal increasingly difficult to achieve.";

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            info1Align: 'unset',
            info2Align: 'unset',
            info3Align: 'unset',
            info4Align: 'unset',
            position: '10%'
        }

        this.alignInfo = this.alignInfo.bind(this);
    }
    alignInfo() {
        if (window.innerWidth < 768) {
            document.getElementById('intro').style.paddingTop = '24px';
            document.getElementById('leftCube').style.top = "500px";
            document.getElementById('info-graphics').style.paddingTop = '50px';

            let position = '7%';
            if (window.innerWidth > 400) {
                position = '10%'
            }
            this.setState({
                info1Align: 'flex-start',
                info2Align: 'center',
                info3Align: 'center',
                info4Align: 'flex-end',
                position: position
            })
        } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
            document.getElementById('intro').style.paddingTop = "24px";
            document.getElementById('info-graphics').style.paddingTop = "50px";
            document.getElementById('leftCube').style.top = "250px";

            this.setState({
                info1Align: 'unset',
                info2Align: 'unset',
                info3Align: 'unset',
                info4Align: 'unset',
                position: '0%'
            });

        } else if (window.innerWidth >= 992) {
            // Add padding to the top of the intro and info graphics so they don't overlap the decorative cubes
            const bgHeight = document.getElementById('cubes').clientHeight;
            document.getElementById('intro').style.paddingTop = `${Math.ceil(bgHeight * .25)}px`;
            document.getElementById('info-graphics').style.paddingTop = `${Math.ceil(bgHeight * .40)}px`;

            // Add a margin top to the incorpoated section of the timeline so it's middle aligned with the dashed line
            const livingRoomHeight = document.getElementById('livingRoom').clientHeight / 2;
            document.getElementById('inc').style.marginTop = `${livingRoomHeight}px`;

            // Add a margin top to the svg following incorporated so it's middle alligned
            const incHeight = document.getElementById('incImg').clientHeight / 2;
            document.getElementById('postIncSVG').style.marginTop = `${livingRoomHeight + incHeight}px`;

            // Align the svg following the software picture on the timeline
            const softwareHeight = document.getElementById('software').clientHeight;
            document.getElementById('postSoftwareSVG').style.marginTop = softwareHeight / 2;

            this.setState({
                info1Align: 'unset',
                info2Align: 'unset',
                info3Align: 'unset',
                info4Align: 'unset',
                position: '0%'
            });

        }
    }

    componentDidMount() {
        // Set page title and meta description
        setTitle(titles);
        setMetaDescription(description);

        // Scroll to the top of the page
        window.scrollTo(0, 0);

        this.alignInfo();

        // Align content with each window resize
        window.onresize = () => {
            this.alignInfo()
        }
    }

    componentWillUnmount() {
        window.onresize = () => { }
    }
    render() {
        return (
            <main className="about" id="about">

                <div className="container-fluid position-relative pt-lg-5">
                    <img src={TopRightCubes} id="cubes" style={{ position: 'absolute', top: '0px', right: '0px', zIndex: '-100', display: 'block', maxWidth: '45vw', height: 'auto' }} alt="" />
                    <img src={LeftSideCube} id="leftCube" className="d-none d-lg-block" style={{ position: 'absolute', top: '250px', left: '0px', zIndex: '-100', maxWidth: '43.2vw' }} alt="" />
                    <div className="container-md position-relative">

                        <div className="row" id="intro">
                            <div className="col-12">
                                <h1>Why grow <br className="d-lg-none" />with Librex?</h1>
                                <p>Librex brings the capabilities of retail fulfillment to every eCommerce seller</p>
                                <ArrowLink classes="h2 purple-link xlarge-link me-auto position-relative" link={GET_STARTED_LINK} text="GO FOR IT" />
                            </div>
                        </div>

                        <div className="row position-relative" id="info-graphics">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-md-end">

                                <div className="d-flex align-items-end mb-5" >
                                    <img src={Customers} alt="" className="me-3" style={{ alignSelf: this.state.info1Align }} />
                                    <div className="d-flex flex-column">
                                        <p className="h3"><strong>800+</strong></p>
                                        <p className="mb-0">Customers</p>
                                    </div>
                                </div>

                                <div className="d-flex align-items-end mb-5" style={{ alignSelf: this.state.info2Align, position: "relative", right: this.state.position }}>
                                    <img src={Transactions} alt="" className="me-3" />
                                    <div className="d-flex flex-column">
                                        <p className="h3"><strong>$180M+</strong></p>
                                        <p className="mb-0">Value Shipped 2020</p>
                                    </div>
                                </div>



                                <div className="d-flex align-items-end mb-5" style={{ alignSelf: this.state.info3Align, position: "relative", left: this.state.position }}>
                                    <img src={SalesIncrease} alt="" className="me-3" />
                                    <div className="d-flex flex-column">
                                        <p className="h3"><strong>32%</strong></p>
                                        <p className="mb-0">Average Customer YOY Growth</p>
                                    </div>
                                </div>



                                <div className="d-flex align-items-end mb-5" style={{ alignSelf: this.state.info4Align }}>
                                    <img src={ResponseTime} alt="" className="me-3" />
                                    <div className="d-flex flex-column">
                                        <p className="h3"><strong>2.5hr</strong></p>
                                        <p className="mb-0">Response Time</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-n5" style={{ paddingTop: '100px' }}>
                            <div className="col-12">
                                <h5 className="mb-3">Our Story</h5>
                            </div>
                            <div className="col-12 col-lg-6">
                                <p>Founded in 2016, Librex has always stuck by the principle that delivering your product should be simple and inexpensive. Retail giants setting customer expectations for 1-2 day shipping has made this goal increasingly difficult to achieve.</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <p>Librex accomplishes simplicity through a 3 step pricing structure, custom software providing improved order insight, and world-class US-based 24/7 customer service.</p>
                            </div>
                        </div>

                        <div className="row pt-5 mt-5 timeline">
                            <div className="col-12 d-flex flex-column flex-lg-row justify-content-around">

                                <div className="d-inline-flex justify-content-between justify-content-lg-start">
                                    <div className="text-center">
                                        <img src={LivingRoom} id="livingRoom" width="219px" height="110px" alt="" />
                                    </div>

                                    <div>
                                        <p className="year-description">
                                            <strong className="h4">2016</strong><br />Packing items on the livingroom floor
                                        </p>
                                        <svg width="228" height="37" viewBox="0 0 228 37" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-none d-lg-block" >
                                            <path d="M2 2V35H226" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                        </svg>
                                    </div>
                                </div>

                                <svg width="3" height="70" viewBox="0 0 3 70" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block d-lg-none my-3" style={{ position: 'relative', left: '60%' }} >
                                    <line x1="1.5" y1="1.5" x2="1.5" y2="68.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>

                                <div className="d-inline-flex justify-content-between justify-content-lg-start align-items-center" id="inc">
                                    <div className="text-center">
                                        <img src={Inc} width="171px" height="99px" alt="" id="incImg" />
                                    </div>

                                    <div>
                                        <p className="year-description">
                                            <strong className="h4">Early 2017</strong><br />Librex was incorporated
                                        </p>
                                    </div>
                                </div>

                                <svg width="3" height="70" viewBox="0 0 3 70" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block d-lg-none my-3" style={{ position: 'relative', left: '60%' }}>
                                    <line x1="1.5" y1="1.5" x2="1.5" y2="68.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>


                                <svg width="134" height="77" viewBox="0 0 134 77" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-none d-lg-block" id="postIncSVG">
                                    <path d="M132 75V2H2" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>
                            </div>

                            <div className="col-12 d-flex flex-column flex-lg-row-reverse justify-content-lg-around align-items-lg-center mt-lg-3">

                                <div className="d-inline-flex align-items-center">
                                    <div className="text-center">
                                        <img src={SmallWarehouse} id="smallWarehouse" width="160px" height="195px" alt="" />
                                    </div>

                                    <div className="ms-lg-3">
                                        <p className="year-description">
                                            <strong className="h4">Mid 2017</strong><br />Started using a small warehouse in Fredrick, Maryland
                                        </p>
                                    </div>
                                </div>


                                <svg width="3" height="70" viewBox="0 0 3 70" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block d-lg-none my-3" style={{ position: 'relative', left: '60%' }}>
                                    <line x1="1.5" y1="1.5" x2="1.5" y2="68.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>

                                <svg width="281" height="3" viewBox="0 0 281 3" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-none d-lg-block mx-4">
                                    <line x1="279.5" y1="1.5" x2="1.5" y2="1.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>

                                <div className="d-inline-flex align-items-center justify-content-between justify-content-lg-start">

                                    <div className="text-center">
                                        <img src={MultiWarehouse} id="multiWarehouse" width="295px" height="185px" alt="" />
                                    </div>
                                    <div className="ms-lg-3">
                                        <p className="year-description">
                                            <strong className="h4">Early 2018</strong><br />Started using multiple small warehouses in Illinois
                                        </p>
                                    </div>

                                </div>


                                <svg width="3" height="70" viewBox="0 0 3 70" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block d-lg-none my-3" style={{ position: 'relative', left: '60%' }}>
                                    <line x1="1.5" y1="1.5" x2="1.5" y2="68.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>
                            </div>

                            <div className="col-12 d-flex flex-column flex-lg-row justify-content-between justify-content-lg-around mt-lg-5">
                                <svg width="111" height="77" viewBox="0 0 111 77" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-none d-lg-block">
                                    <path d="M2 2L1.99999 75L109 75" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>

                                <div className="d-inline-flex align-items-center">
                                    <div className="text-center">
                                        <img src={Software} id="software" width="333px" height="215px" alt="" />
                                    </div>
                                    <div className="ms-lg-4">
                                        <p className="year-description">
                                            <strong className="h4">Late 2018</strong><br />Launched our portal software services
                                        </p>
                                    </div>
                                </div>


                                <svg width="3" height="70" viewBox="0 0 3 70" fill="none" xmlns="http://www.w3.org/2000/svg" className="d-block d-lg-none my-3" style={{ position: 'relative', left: '60%' }}>
                                    <line x1="1.5" y1="1.5" x2="1.5" y2="68.5" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>

                                <svg width="194" height="129" viewBox="0 0 194 129" fill="none" xmlns="http://www.w3.org/2000/svg" id="postSoftwareSVG" className="d-none d-lg-block">
                                    <path d="M192 127V2H2.00001" stroke="#D0D9E7" strokeWidth="3" strokeLinecap="round" strokeDasharray="4 10" />
                                </svg>
                            </div>

                            <div className="col-12 d-flex flex-column flex-lg-row-reverse justify-content-between justify-content-lg-around mt-lg-5">
                                <div className="d-inline-flex align-items-start">
                                    <div className="text-center">
                                        <img src={BigWarehouse} id="bigWarehouse" width="522px" height="358px" alt="" onLoad={this.alignInfo} />
                                    </div>

                                    <div>
                                        <p className="year-description">
                                            <strong className="h4">Early 2019</strong><br />Started using large warehouse
                                        </p>
                                    </div>

                                </div>

                                <div style={{ maxWidth: "545px" }} className="mt-5 mt-lg-0">
                                    <h5>How We Grew</h5>
                                    <p>Our story begins by helping friends and family overseas from our living room. On a daily basis we would pack all their orders and drive them down to our local post office.</p>

                                    <p>It didn't take long for word of our services to spread and our operation to out grow the living room. We needed help so we turned to the internet in search for reliable fulfillment partners. Our research left us empty handed and feeling uncomfortable with the options in the market. This is when we decided that e-commerce companies need a simple seamless extension to scale the physical aspects of their business.</p>

                                    <p>Our first expansion was to a storage facility close to home. We quickly took up all of the large storage units and were forced to expand again. This time we moved into our first warehouse located in Frederick, Maryland. Shortly after Librex added a second warehouse in Chicago, Illinois to handle bulk LTL services.</p>

                                    <p>After building our warehouse network it was time for Librex to refine our customer experience. Increasing transparency in our operations was the first step, which is why we launched our cloud-based portal service Librex GO. With Librex GO our clients gain full control over their products stored in our warehouse and increased visibility into our day-to-day operations.</p>

                                    <p>From day one Librex has believed in transparent pricing and a simple fulfillment process. Schedule a phone call with us today to learn how you can become a part of the Librex family.</p>
                                </div>
                            </div>

                        </div>

                        <div className="row py-lg-5 mt-5">
                            <div className="col-12">
                                <h5>Our Locations</h5>
                            </div>
                            <div className="col-12 text-center pt-5">
                                <img src={LocationsMap} alt="Librex has locations in Frederick, MD; Chicago, IL; and Los Angeles, CA" className="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5 position-relative">
                        <div className="col-12 d-flex justify-content-center">
                            <OnboardingContactForm />
                        </div>
                        <HalfHeightPurpleGradient />
                    </div>
                </div>

            </main>

        );
    }
}

export default About;