export const WHATS_APP_HREF = "https://wa.me/12394519226";
/* Librex website links */
export const HOME_LINK = "/";
export const FULFILLMENT_LINK = "/fulfillment";
export const FBA_PREP_LINK = "/fba-prep";
export const GET_STARTED_LINK = "/get-started";
export const SOFTWARE_LINK = "/software";
export const ABOUT_LINK = "/about";
export const TERMS_OF_SERVICE_LINK = "/terms-of-service";

/**
 * Phones numbers
 */
export const PHONE_NUMBER__LIBREX_SUPPORT = "+1 (239) 451-9226";
export const PHONE_LINK__LIBREX_SUPPORT = "tel:+1-239-451-9226";

export const PHONE_NUMBER__LIBREX_SALES = "+1 (301) 337-5467";
export const PHONE_LINK__LIBREX_SALES = "tel:+1-301-337-5467";

/**
 * Emails
 */
export const EMAIL__LIBREX_SALES = "sales@librexfulfillment.com";
export const EMAIL_LINK__LIBREX_SALES = `mailto:${EMAIL__LIBREX_SALES}`;

export const EMAIL__LIBREX_SUPPORT = "support@librexfulfillment.com";
export const EMAIL_LINK__LIBREX_SUPPORT = `mailto:${EMAIL__LIBREX_SUPPORT}`;