import React from 'react';

import { setTitle, setMetaDescription } from '../utilities';

// Sections
import FeaturesWeOffer from '../sections/FeaturesWeOffer';

// Components
import NoJumpHeader from '../components/reusable/NoJumpHeader';

// Graphics
import TopRightGraphic from '../assets/graphics/software/top-right-cubes.svg';
import SoftwareGraphic from '../assets/graphics/software/softwareBackground.svg';
import DesktopPreview from '../assets/graphics/software/softwareDesktop.svg';
import MobilePreview from '../assets/graphics/software/softwareMobile.svg';
import BoxTop from '../assets/graphics/software/boxAnimation/boxTop.svg';
import BoxBottom from '../assets/graphics/software/boxAnimation/boxBottom.svg';

// Features Icons
import Support from '../assets/graphics/software/features/support.svg';
import OrderTracking from '../assets/graphics/software/features/orderTracking.svg';
import SoftwareSuite from '../assets/graphics/software/features/softwareSuite.svg';
import Integrations from '../assets/graphics/software/features/integrations.svg';
import DataImporting from '../assets/graphics/software/features/dataImporting.svg';
import RealtimeUpdates from '../assets/graphics/software/features/realtimeUpdates.svg';
import InventoryManagement from '../assets/graphics/software/features/inventoryManagement.svg';
import CloudBased from '../assets/graphics/software/features/cloudBased.svg';
import ReturnManagement from '../assets/graphics/software/features/returns.svg';
import ReportingAndAnalytics from '../assets/graphics/software/features/analytics.svg';
import AutomatedOrderProcessing from '../assets/graphics/software/features/orderProcessing.svg';
import BrandedPackaging from '../assets/graphics/software/features/brandedPackaging.svg';

// Integrations image - png
import AmazonPNG from '../assets/icons/integrations/amazon.png';
import EbayPNG from '../assets/icons/integrations/ebay.png';
import ShopifyPNG from '../assets/icons/integrations/shopify.png';
import SquarespacePNG from '../assets/icons/integrations/squarespace.png';
import WalmartPNG from '../assets/icons/integrations/walmart.png';
import WixPNG from '../assets/icons/integrations/wix.png';
import SellbritePNG from '../assets/icons/integrations/sellbrite.png';
import StitchLabsPNG from '../assets/icons/integrations/stitchLabs.png';

// Integrations image - webp
import AmazonWEBP from '../assets/icons/integrations/amazon.webp';
import EbayWEBP from '../assets/icons/integrations/ebay.webp';
import ShopifyWEBP from '../assets/icons/integrations/shopify.webp';
import SquarespaceWEBP from '../assets/icons/integrations/squarespace.webp';
import WalmartWEBP from '../assets/icons/integrations/walmart.webp';
import WixWEBP from '../assets/icons/integrations/wix.webp';

// Integrations image - svg
import EtsySVG from '../assets/icons/integrations/etsy.svg';
import WishSVG from '../assets/icons/integrations/wish.svg';
import ThreeDCartSVG from '../assets/icons/integrations/3dCart.svg';
import BigCommerceSVG from '../assets/icons/integrations/bigcommerce.svg';
import ecwidSVG from '../assets/icons/integrations/ecwid.svg';
import GoogleSVG from '../assets/icons/integrations/google.svg';
import MagentoSVG from '../assets/icons/integrations/magento.svg';
import OpenCartSVG from '../assets/icons/integrations/openCart.svg';
import WeeblySVG from '../assets/icons/integrations/weebly.svg';
import WooCommerceSVG from '../assets/icons/integrations/wooCommerce.svg';

const titles = ["Software", "Portal Dashboard"];
const description = "Get real time updates and manage every aspect of your ecommerce business online or through our app. View orders, inventory, inbound shipments and more at a glance.";

const DESKTOP_TOP = 74
class Software extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            animationInterval: null
        }
        this.desktopPreviewRef = React.createRef();
    }

    alignItems() {
        if (window.innerWidth >= 768) {
            const graphicHeight = this.desktopPreviewRef.current.clientHeight;
            const graphicWidth = this.desktopPreviewRef.current.clientWidth;

            document.getElementById('mobilePreview').style.right = `${graphicWidth * .54}px`;
            document.getElementById('mobilePreview').style.top = `${DESKTOP_TOP + (graphicHeight * .25)}px`;
            document.getElementById('intro').style.top = `${DESKTOP_TOP + (graphicHeight * .2)}px`;
        }
    }

    integrationsCollapseListeners() {
        document.getElementById('integrationsCollapse').addEventListener("show.bs.collapse", () => {
            const collapseElement = document.getElementById('integrationCollapseToggle');
            collapseElement.innerHTML = 'SEE LESS INTEGRATIONS';
            collapseElement.parentElement.classList.add('expanded');
        });
        document.getElementById('integrationsCollapse').addEventListener("hide.bs.collapse", () => {
            const collapseElement = document.getElementById('integrationCollapseToggle');
            collapseElement.innerHTML = 'SEE MORE INTEGRATIONS';
            collapseElement.parentElement.classList.remove('expanded');
        });
    }


    componentDidMount() {
        // Set page title and meta description
        setTitle(titles);
        setMetaDescription(description);
        
        // Scroll to the top of the page IF there isn't an ID attached to the URL
        if (window.location.toString().split('#').length === 1) {
            window.scrollTo(0, 0);
        }


        this.desktopPreviewRef.current.onload = () => {
            this.alignItems();
        }
        window.onresize = () => {
            this.alignItems();
        }

        this.integrationsCollapseListeners();

        const animationInterval = setInterval(() => {
            const boxContainer = document.getElementById('boxContainer');
            if( boxContainer.classList.contains('box-closed') ){
                boxContainer.classList.remove('box-closed');
                boxContainer.classList.add('box-opened');
            }else{
                boxContainer.classList.remove('box-opened');
                boxContainer.classList.add('box-closed');
            }
        },4000)

        this.setState({
            animationInterval: animationInterval
        })

    }

    componentWillUnmount() {
        clearInterval(this.state.animationInterval);
        this.desktopPreviewRef.current.onload = () => { }
        window.onresize = () => { }

    }

    render() {
        const software_hero_aspect_ratio = "calc(1043/1920*100%)"
        return (
            <main className="software mb-5" id="main" >
                <div className="container-fluid px-0 mb-lg-5 position-relative">
                    <NoJumpHeader className="d-none d-md-block" aspect_ratio={software_hero_aspect_ratio}>
                        <img src={SoftwareGraphic} className="img-fluid d-none d-md-block" alt="" style={{position: "absolute", top: 0, left: 0}}/>
                    </NoJumpHeader>

                    <img src={TopRightGraphic} className="img-fluid d-block d-md-none" alt="" style={{ position: 'absolute', top: '0px', right: '0px', maxWidth: '50vw', maxHeight: '350px', zIndex: '-100' }} />
                    <img src={DesktopPreview} className="img-fluid d-none d-md-block" alt="" style={{ position: 'absolute', top: '74px', right: '0px', width: '51vw' }} ref={this.desktopPreviewRef} />
                    <img src={MobilePreview} id="mobilePreview" className="img-fluid d-none d-md-block" alt="" style={{ position: 'absolute', top: '198px', right: '390px', width: '19vw' }} />
                    <div className="container-md" id="intro">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <h1>Stay <span className="text-cool-blue-gradient">connected.</span> <br className="d-lg-none" /></h1>
                                <p>Your eCommerce stores all in one place</p>
                            </div>
                        </div>
                    </div>
                    <div className="container-md">
                        <div className="row">
                            <div className="col-8 col-md-6 offset-md-6">
                                <p className="section-lead">Never miss a beat with LIBREX. Manage your eCommerce store from anywhere on any computer, phone or tablet.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <FeaturesWeOffer title="Services We Offer" />

                <div className="container-md">
                    <div className="row">
                        <div className="col-12 bg-white-gradient software-features py-5">
                            <h5>Software Features</h5>
                            <div className="d-flex flex-wrap justify-content-between">

                                <div className="single-feature">
                                    <img src={Support} className="img-fluid" alt="" />
                                    <p>24/7 U.S. Based <br className="d-lg-none" />Customer Support</p>
                                </div>
                                <div className="single-feature">
                                    <img src={Integrations} className="img-fluid" alt="" />
                                    <p>Unlimited Selling <br className="d-lg-none" />Integrations</p>
                                </div>
                                <div className="single-feature">
                                    <img src={DataImporting} className="img-fluid" alt="" />
                                    <p>Data Importing</p>
                                </div>
                                <div className="single-feature">
                                    <img src={RealtimeUpdates} className="img-fluid" alt="" />
                                    <p>Real-time Updates</p>
                                </div>


                                <div className="single-feature">
                                    <img src={ReturnManagement} className="img-fluid" alt="" />
                                    <p>Return <br className="d-lg-none" />Management</p>
                                </div>
                                <div className="single-feature">
                                    <img src={ReportingAndAnalytics} className="img-fluid" alt="" />
                                    <p>Reporting &amp; <br className="d-lg-none" />Analytics </p>
                                </div>
                                <div className="single-feature">
                                    <img src={SoftwareSuite} className="img-fluid" alt="" />
                                    <p>On Website <br className="d-lg-none" />Software Suite</p>
                                </div>
                                <div className="single-feature">
                                    <img src={InventoryManagement} className="img-fluid" alt="" />
                                    <p>Inventory <br className="d-lg-none" />Management</p>
                                </div>


                                <div className="single-feature">
                                    <img src={OrderTracking} className="img-fluid" alt="" />
                                    <p>Order Tracking</p>
                                </div>
                                <div className="single-feature">
                                    <img src={CloudBased} className="img-fluid" alt="" />
                                    <p>Cloud Based <br className="d-lg-none" />Platform</p>
                                </div>

                                <div className="single-feature">
                                    <img src={AutomatedOrderProcessing} className="img-fluid" alt="" />
                                    <p>Automated Order Processing</p>
                                </div>
                                <div className="single-feature">
                                    <img src={BrandedPackaging} className="img-fluid" alt="" />
                                    <p>Branded Packaging <br className="d-lg-none" />Slips &amp; Labels</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid bg-white-gradient py-3 py-lg-5">
                    <div className="anchor" id="integrations"></div>
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12">
                                <h5>Integrations</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <h1 className="mb-4" style={{ maxWidth: '600px' }}>LIBREX’s software allows for limitless multi-channel integrations</h1>
                                <p className="d-lg-none" style={{ maxWidth: '600px' }}>Librex offers unlimited selling integrations for all accounts via custom API’s to top ecommerce platforms. We can integrate them all!</p>
                                <p className="d-none d-lg-block mb-0" style={{ maxWidth: '600px' }}>LIBREX’s software allows for limitless multi-channel integrations that can be used seamlessly with your online store. This is great for a lot of reasons and makes it so easy to use Librex. That is why it is so helpful.</p>
                            </div>
                            <div className="col-6 d-none d-lg-block position-relative box-closed" id="boxContainer">
                                <img src={BoxTop} alt="" id="boxTop" style={{ maxWidth: '151px', position: 'absolute', zIndex: '0', bottom: '60px', left: '50%'}} />

                                <picture>
                                    <source srcSet={AmazonWEBP} type="image/webp" />
                                    <source srcSet={AmazonPNG} type="image/png" />
                                    <img src={AmazonPNG} id="amazonAnimated" className="animated-integration" alt="Integrate Amazon with Librex GO" />
                                </picture>

                                <picture>
                                    <source srcSet={ShopifyWEBP} type="image/webp" />
                                    <source srcSet={ShopifyPNG} type="image/png" />
                                    <img src={ShopifyPNG} id="shopifyAnimated" className="animated-integration" alt="Integrate Shopify with Librex GO" />
                                </picture>

                                <picture>
                                    <source srcSet={EbayWEBP} type="image/webp" />
                                    <source srcSet={EbayPNG} type="image/png" />
                                    <img src={EbayPNG} id="ebayAnimated" className="animated-integration"  alt="Integrate Ebay with Librex GO" />
                                </picture>

                                <picture>
                                    <source srcSet={WalmartWEBP} type="image/webp" />
                                    <source srcSet={WalmartPNG} type="image/png" />
                                    <img src={WalmartPNG} id="walmartAnimated" className="animated-integration" alt="Integrate Walmart with Librex GO" />
                                </picture>

                                <img src={WooCommerceSVG} id="woocommerceAnimated" className="animated-integration" alt="Integrate WooCommerce with Librex GO" />

                                <img src={EtsySVG} id="etsyAnimated" className="animated-integration" alt="Integrate Etsy with Librex GO" />

                                <img src={BoxBottom} alt="" style={{ maxWidth: '145px', position: 'absolute', zIndex: '-100', bottom: '0px', left: '50%', transform: 'translateX(-50%)' }}/>
                            </div>
                        </div>

                        <div className="row pt-5">
                            <div className="col-12">
                                <div className="d-flex flex-wrap justify-content-between align-items-end integrations">

                                    <div>
                                        <picture>
                                            <source srcSet={EbayWEBP} type="image/webp" />
                                            <source srcSet={EbayPNG} type="image/png" />
                                            <img src={EbayPNG} className="img-fluid" alt="Integrate Ebay with Librex GO" />
                                        </picture>
                                        <p>Ebay</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={AmazonWEBP} type="image/webp" />
                                            <source srcSet={AmazonPNG} type="image/png" />
                                            <img src={AmazonPNG} className="img-fluid" alt="Integrate Amazon with Librex GO" />
                                        </picture>
                                        <p>Amazon Marketplace</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={ShopifyWEBP} type="image/webp" />
                                            <source srcSet={ShopifyPNG} type="image/png" />
                                            <img src={ShopifyPNG} className="img-fluid" alt="Integrate Shopify with Librex GO" />
                                        </picture>
                                        <p>Shopify</p>
                                    </div>

                                    <div>
                                        <picture>
                                            <source srcSet={WalmartWEBP} type="image/webp" />
                                            <source srcSet={WalmartPNG} type="image/png" />
                                            <img src={WalmartPNG} className="img-fluid" alt="Integrate Walmart with Librex GO" />
                                        </picture>
                                        <p>Walmart</p>
                                    </div>

                                    <div>
                                        <img src={WooCommerceSVG} className="img-fluid" alt="Integrate WooCommerce with Librex GO" />
                                        <p>WooCommerce</p>
                                    </div>

                                    <div>
                                        <img src={EtsySVG} className="img-fluid" alt="Integrate Etsy with Librex GO" />
                                        <p>Etsy</p>
                                    </div>

                                </div>

                                <div className="collapse" id="integrationsCollapse">
                                    <div className="d-flex flex-wrap justify-content-between align-items-end integrations">
                                        <div>
                                            <img src={GoogleSVG} className="img-fluid" alt="Integrate Google with Librex GO" />
                                            <p>Google</p>
                                        </div>

                                        <div>
                                            <img src={BigCommerceSVG} className="img-fluid" alt="Integrate Big Commerce with Librex GO" />
                                            <p>BigCommerce</p>
                                        </div>

                                        <div>
                                            <picture>
                                                <source srcSet={WixWEBP} type="image/webp" />
                                                <source srcSet={WixPNG} type="image/png" />
                                                <img src={WixPNG} className="img-fluid" alt="Integrate Wix with Librex GO" />
                                            </picture>
                                            <p>Wix</p>
                                        </div>

                                        <div>
                                            <picture>
                                                <source srcSet={SquarespaceWEBP} type="image/webp" />
                                                <source srcSet={SquarespacePNG} type="image/png" />
                                                <img src={SquarespacePNG} className="img-fluid" alt="Integrate Squarespace with Librex GO" />
                                            </picture>
                                            <p>Squarespace</p>
                                        </div>

                                        <div>
                                            <img src={WishSVG} className="img-fluid" alt="Integrate Wish with Librex GO" />
                                            <p>Wish</p>
                                        </div>
                                        <div>
                                            <img src={WeeblySVG} className="img-fluid" alt="Integrate Weebly with Librex GO" />
                                            <p>Weebly</p>
                                        </div>

                                        <div>
                                            <img src={MagentoSVG} className="img-fluid" alt="Integrate Magento with Librex GO" />
                                            <p>Magento</p>
                                        </div>

                                        <div>
                                            <picture>
                                                {/* <source srcSet={StitchLabsWEBP} type="image/webp" /> */}
                                                <source srcSet={StitchLabsPNG} type="image/png" />
                                                <img src={StitchLabsPNG} className="img-fluid" alt="Integrate StitchLabs with Librex GO" />
                                            </picture>
                                            <p>StitchLabs</p>
                                        </div>

                                        <div>
                                            <img src={ecwidSVG} className="img-fluid" alt="Integrate Ecwid with Librex GO" />
                                            <p>Ecwid</p>
                                        </div>

                                        <div>
                                            <picture>
                                                {/* <source srcSet={SellbriteWEBP} type="image/webp" /> */}
                                                <source srcSet={SellbritePNG} type="image/png" />
                                                <img src={SellbritePNG} className="img-fluid" alt="Integrate Sellbrite with Librex GO" />
                                            </picture>
                                            <p>Sellbrite</p>
                                        </div>

                                        <div>
                                            <img src={ThreeDCartSVG} className="img-fluid" alt="Integrate 3dCart with Librex GO" />
                                            <p>3dcart</p>
                                        </div>

                                        <div>
                                            <img src={OpenCartSVG} className="img-fluid" alt="Integrate OpenCart with Librex GO" />
                                            <p>OpenCart</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-end see-more">
                                <a className="purple-link large-link" href="#integrationsCollapse" id="integrationCollapseToggle" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="integrationsCollapses">SEE MORE INTEGRATIONS</a>
                                <span className="indicator my-auto">
                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 7L8.04806 2L15 7" stroke="#8B00F8" strokeWidth="3" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Software;