import React from "react";
import { GET_STARTED_LINK } from "../../Constants";
import ArrowLink from "./ArrowLink";

function GetAQuoteLink(props){
    return (
        <ArrowLink classes="purple-link large-link position-relative d-inline-block mt-3" link={GET_STARTED_LINK} text="GET A QUOTE"/>
    );
}

export default GetAQuoteLink;