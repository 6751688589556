// Routing
import {
    Link
} from "react-router-dom";


import { ABOUT_LINK, FBA_PREP_LINK, FULFILLMENT_LINK, GET_STARTED_LINK, HOME_LINK, SOFTWARE_LINK, TERMS_OF_SERVICE_LINK, PHONE_NUMBER__LIBREX_SUPPORT, PHONE_LINK__LIBREX_SUPPORT, PHONE_NUMBER__LIBREX_SALES, PHONE_LINK__LIBREX_SALES, WHATS_APP_HREF, EMAIL_LINK__LIBREX_SALES, EMAIL__LIBREX_SALES } from "../Constants"

// Icons
import Phone from '../assets/icons/phone.svg';
import Envelope from '../assets/icons/envelope.svg';
import Instgram from '../assets/icons/instagramWhite.svg';
import Facebook from '../assets/icons/facebookWhite.svg';
import WhatsApp from "../assets/icons/whatsAppWhite.svg";

// Components
import SchedulePhoneCall from "./SchedulePhoneCall";
import LogoWhite from "./LogoWhite";

function Footer() {
    return (
        <footer className="d-flex align-items-center py-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 d-flex flex-column" style={{zIndex: '100'}}>
                        <p className="mb-1"><Link to="/"><LogoWhite /></Link></p>
                        <p className="text-thin">Trusted by Online Sellers Worldwide</p>
                        <div className="divider mb-3"></div>
                        <p><img src={Phone} alt="Call Librex Fulfillments Support number" className="icon-small mr-2" /><a className="ps-2" href={PHONE_LINK__LIBREX_SUPPORT}>{PHONE_NUMBER__LIBREX_SUPPORT} - Support</a></p>
                        <p><img src={Phone} alt="Call Librex Fulfillments Sales number" className="icon-small mr-2" /><a className="ps-2" href={PHONE_LINK__LIBREX_SALES}>{PHONE_NUMBER__LIBREX_SALES} - Sales (10AM - 8PM EST)</a></p>
                        <p><img src={Envelope} alt="Email Librex" className="icon-small" /><a className="ps-2" href={EMAIL_LINK__LIBREX_SALES}>{EMAIL__LIBREX_SALES}</a></p>
                        <ul className="nav align-items-center">
                            <a href="http://www.instagram.com/librexfulfillment/" className="me-3" target="_blank" rel="noreferrer nooopener"><img src={Instgram} alt="Instagm camera icon" className="img-fluid icon" /><span className="visually-hidden">Connect with us on Instagram</span></a>
                            <a href="https://www.facebook.com/librexofficial/" className="me-3" target="_blank" rel="noreferrer nooopener"><img src={Facebook} alt="Facebook f icon" className="img-fluid icon" /><span className="visually-hidden">Connect with us on Facebook</span></a>
                            <a href={WHATS_APP_HREF} target="_blank" rel="noreferrer nooopener"><img src={WhatsApp} alt="Whats app icon" className="img-fluid icon" /><span className="visually-hidden">Connect with us on Whats App</span></a>
                        </ul>
                        <p className="mt-5 pt-5">© {new Date().getFullYear()} Librex Fulfillment LLC.</p>
                    </div>

                    <div className="col-12 col-lg-3" style={{ zIndex: '100' }}>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <Link className="nav-link" to={HOME_LINK}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={FULFILLMENT_LINK}>Fulfillment</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={FBA_PREP_LINK}>FBA Prep</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={SOFTWARE_LINK}>Software</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={ABOUT_LINK}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={GET_STARTED_LINK}>Get Started</Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://portal.librexfulfillment.com">Librex GO</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={TERMS_OF_SERVICE_LINK}>Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="col-12 col-lg-3" style={{ zIndex: '100' }}>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <span role="button" className="nav-link" onClick={() => { document.getElementById('zsiq_float').click() }}>Live Chat</span>
                            </li>
                            <li className="nav-item">
                                <span className="nav-link" data-bs-toggle="modal" data-bs-target="#schedulePhoneCallModal">Schedule a Phone Call</span>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={GET_STARTED_LINK}>Contact Us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`${GET_STARTED_LINK}#faq`}>FAQ</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <SchedulePhoneCall />
        </footer>
    );
}

export default Footer;