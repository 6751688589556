import PropTypes from "prop-types"
function NoJumpHeader(props) {
    return (
        <div className={props.className } style={{ position: "relative", height: 0, paddingTop: props.aspect_ratio, ...props.style }}>
            {props.children}
        </div>
    );
}

NoJumpHeader.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    aspect_ratio: PropTypes.string.isRequired
}

NoJumpHeader.defaultProps = {
    style: {},
    className: ""
}

export default NoJumpHeader;