// Fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

// 5 Star icon
import Stars5 from '../../assets/icons/trustpilot/stars-5.svg';

function TrustpilotReview(props) {
    return (
        <div className="card trustpilot-review">
            <div className="card-body">
                <div className="d-flex mb-3 pb-3 align-items-center border-bottom">
                    <div className="circle bg-light-grey">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className="ms-3">
                        <p className="mb-1 name">{props.name}</p>
                        <p className="mb-0 location"><FontAwesomeIcon icon={faMapMarkerAlt} className="me-1" /> {props.location}</p>
                    </div>
                </div>

                <div className="d-flex">
                    <img src={Stars5} className="rating" alt="5 star rating" />
                    <p className="ms-auto"><small>{props.reviewDate}</small></p>
                </div>
                <h6>{props.reviewTitle}</h6>
                <p>{props.reviewBody}</p>
            </div>
        </div>
    );
}

export default TrustpilotReview;