import PropTypes from "prop-types";

function ImageTitleText(props){
    return (
        <div className="d-flex flex-column">
            <div className="img-background d-flex align-items-center justify-content-center p-5">
                <img src={props.src} alt={props.alt} />
            </div>
            <h3 className="my-4">{props.title}</h3>
            <p>{props.text}</p>
        </div>
    );
}

ImageTitleText.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string
}

export default ImageTitleText;

