const TERM_SECTION = {
    title: "TERM",
    content: [
        {
            title: "Term",
            content: <p>The term of this Agreement remains in place indefinitely unless and until terminated as provided under this Agreement or applicable law. Either party can terminate this Agreement if they provide at least thirty (30) days’ notice. On termination of the Agreement, both parties will follow the terms provided in Section 9.</p>
        }
    ]
}

const SERVICES_SECTION = {
    title: "LIBREX FULFILLMENT SERVICES",
    content: [
        {
            title: "Services",
            content: <>
                <p>Librex fulfillment will provide the following services</p>
                <ol>
                    <li>Receive inventory on behalf of the Customer or Customers manufactures/suppliers.</li>
                    <li>Provide storage for inventory throughout Librex’s fulfillment network.</li>
                    <li>Perform pick, pack, and shipping of the products from the available inventory and ship such products directly to the end-user.</li>
                    <li>Forward inventory to B2B customers and Amazon Fulfillment centers (where applicable).</li>
                    <li>Facilitate product returns from end users.</li>
                    <li>Provide free software to manage the fulfillment process. </li>
                    <li>Provide free appropriate packaging materials (bubble wrap, boxes with void fill, etc.) where applicable at its discretion.</li>
                    <li>Process, pack and ship all orders following Librex Fulfillment guidelines.</li>
                    <li>Assist in special projects requested by the customer.</li>
                </ol>
            </>
        },
        {
            title: "Service Level Agreement",
            content: <p>By signing up or using Librex Fulfillment’s services, the Customer agrees to the cost of service and terms of this agreement. Librex Fulfillment periodically updates the terms of service agreement. When terms are updated, Customers will be updated by email and through the Librex Go Dashboard.</p>
        }
    ]
}

const PAYMENT_TERMS_SECTION = {
    title: "PAYMENT TERMS AND CONDITIONS",
    content: [
        {
            title: "Payments & Invoicing",
            content: <p>Librex Fulfillment utilizes a pay-as-you-use billing model. A valid credit/debit card is required to be kept on file and will be charged at the end of each business day. </p>
        },
        {
            title: "Options to Pay",
            content: <p>Librex Fulfillment offers the convenience to pay invoices online. We offer the following payment methods: Credit Card (3% processing fee), Bank Transfer &amp; ACH, or a wire transfer ($15 per transfer) </p>
        },
        {
            title: "Late Payment Fee",
            content: <p>If a payment is late 5 calendar days a fee of 5% (Maximum of $ 50) will be applied to each invoice unless the invoice is currently under investigation or dispute. If a Customer’s invoice remains unpaid for more than 15 days, Librex has the right to suspend the account. A $250 fee will apply to reinstate account privileges.</p>
        },
        {
            title: "Privacy",
            content: <p>Librex Fulfillment utilizes a 3rd party payment processing company, Stripe. Librex Fulfillment does NOT store any payment information on internal servers; Stripe stores and processes all payment information.</p>
        },
        {
            title: "Taxes",
            content: <p>You are responsible for all sales, goods and services, use, excise, import, export, value-added, consumption, and other taxes and duties assessed, incurred, or required to be collected or paid by Librex Fulfillment on behalf of the Customer. To the extent Librex Fulfillment is required by law to collect such taxes relative to the services provided, those amounts shall be added to the invoices and paid in full by you.</p>
        }
    ]
}

const RISK_OF_LOSS_SECTION = {
    title: "RISK OF LOSS AND INSURANCE",
    content: [
        {
            title: "Insurance",
            content: <p>It is the Customers sole responsibility to insure their inventory stored within Librex’s fulfillment centers. The Customer hereby agrees that it’s their responsibility, at all times, to maintain an insurance policy that covers the cost of the products held in Librex’s possession. The Customer has the option to select from one of Librex’s partner insurance providers or provide their own coverage. If customers provide their own coverage options, Librex shall allow warehouse inspections for insurance agents to assist you in obtaining coverage.</p>
        },
        {
            title: "No Insurance",
            content: <p>The Customer can choose not to insure their inventory. In this event, Librex Fulfillment will, under no circumstances will be liable for any loss or damage to the inventory stored at Librex Fulfillment facilities.</p>
        },
        {
            title: "Risk of Loss",
            content: <p>The Customer hereby agrees that at no time during the period inventory is held in Librex’s possession, Librex will be liable for inventory damages or shortages. Inventory damage and shortages will be under the responsibility of the Customer. Librex Fulfillment exercises commercially reasonable standards of care for all your products in its possession but specifically disclaims liability of loss except for instances of gross negligence by Librex Fulfillment. In the event of inventory loss due to inventory count inaccuracies that is in possession of Librex Fulfillment, the Customer agrees that it will be considered an “inventory loss”.</p>
        }
    ]
}

const STORAGE_AND_INVENTORY_SECTION = {
    title: "STORAGE AND INVENTORY MANAGEMENT",
    content: [
        {
            title: "Inbound Shipments",
            content: <p>It is the Customers responsibility to prepare their inbound shipments to Librex’s receiving standards. For all products arriving at Librex’s fulfillment centers, an inbound shipment record must be created in the LibrexGo Dashboard and the proper carton/pallet identifying labels must be applied visibly to the outside of the shipment. Librex may refuse, reject or hold inventory for further processing if an inbound shipment is non-compliant with Librex receiving standards. For shipments that don’t comply with Librex’s label requirements, receiving will be charged at the rate of $50/hour. Inventory will be held at the rate of $50/day for shipments noncompliant with LibrexGO Dashboard entries and label requirements. If items are not claimed 15 days after arrival, the inventory is subject to disposal.</p>
        },
        {
            title: "Inventory Receiving",
            content: <p>Librex accepts inbound shipments Monday to Friday from 10:00 AM EST – 4:30 PM EST. Upon receiving the Customer’s inventory, Librex will inspect the shipment for damages and compliance with Librex receiving standards. All products shipped to Librex Fulfillment must be received in a ready-to-ship format unless an arrangement has been made with a Librex Representative.</p>
        },
        {
            title: "Inventory Processing",
            content: <p>Librex shall compare the inbound pallet/case quantities listed on the WRO (Warehouse Receiving Order) with the information provided on the LibrexGo Dashboard. Librex’s warehouse team will not verify the quantities inside each case/pallet. Librex's counts are based on the master carton markings. Customers will be notified of any discrepancies or exterior physical damage observed upon receiving the products.</p>
        },
        {
            title: "Delivery by Appointment",
            content: <p>It is the Customers responsibility to update the estimated arrival date along with the tracking number when creating an inbound shipment in the LibrexGo Dashboard. All LTL, truckload, and full container shipments are required to schedule an appointment 48 hours before delivery. Librex Fulfillment will not be liable for any fees associated with refused shipments.</p>
        },
        {
            title: "Inbound Labelling",
            content: <p>All inbound master cartons and pallets are required to be identified with the correct labels obtained in the LibrexGo Dashboard.</p>
        },
        {
            title: "Storage",
            content: <p>Storage is charged on the first weekday of each calendar month. Products received after the 15th calendar day of the month will be charged prorated storage fees. Each SKU requires its own separate storage space to ensure an accurate pick & pack process. Librex Fulfillment offers two storage options for fulfillment customers. Inventory can be stored in Pallet or In House Storage (less than pallet) configurations. Librex accepts and stores all standard 48”x40x48” pallets. Pallets may NOT exceed the height of 48”. Pallets that exceed 48” in height will be redistributed into smaller pallets.</p>
        },
        {
            title: "Inventory Counts / Management",
            content: <p>All Inventory counts are tracked by Librex’s internal warehouse management system (WMS). Inventory counts are available live on the LibrexGo Dashboard. Librex does not hold previous inventory records on its servers. As described in section 5.3, products are not counted by individual piece upon arrival (unless otherwise specified). Librex Fulfillment shall not be responsible for any variance in the total volume of any Product held in inventory. Additional or annual physical inventory counts can be scheduled on request and will incur an additional cost at the rate of $40 per hour.</p>
        },
        {
            title: "INVENTORY COUNT INACCURACIES",
            content: <p>In the event of inventory loss due to inventory count inaccuracies, the Customer agrees that it will be considered an “inventory loss” and in no event shall Librex Fulfillment be liable.</p>
        }
    ]
}

const FULFILLMENT_SERVICES_SECTION = {
    title: "FULFILLMENT SERVICES",
    content: [
        {
            title: "Shipping with Librex Fulfillment",
            content: <p>All orders will be shipped using the carrier and service level selected by Librex Fulfillment in its reasonable discretion based on the shipping method that is provided via integrated Store or Marketplace. </p>
        },
        {
            title: "Packaging",
            content: <p>Librex will provide Customers with free standard packaging for all items under 12 in dimension(12x12x12"). Standard packaging includes brown cardboard boxes, poly mailers, and padded poly mailers. If the final shipping dimensions are over 12 inches, the Customer can purchase custom packaging materials from Librex or provide their own. If the Product does not require any additional packaging, the shipping label and packing slip will be applied to the exterior of the Product.</p>
        },
        {
            title: "Shipping Times",
            content: <>
                <p>Based on the final package dimensions and destination, Librex's Order Management System (OMS) will browse through the following carriers: United Parcel Service, Inc. (“UPS”), FedEx Corporation (“FedEx”), United States Postal Service (“USPS”) and DHL eCommerce (“DHL”) (each, individually, a “Carrier,” and collectively, the “Carriers”), to find the best shipping option.</p>
                <ul>
                    <li>(A) All orders are shipped with standard 2–3-day shipping anywhere in the U.S. Orders will be fulfilled the same day if received by 12:30 PM EST. All overnight and expedited shipping requests must be specified on the order. Orders being delivered by overnight or expedited shipping option must be placed by 12 PM EST.</li>
                    <li>(B) Librex is not liable for shipping delays due to unforeseen circumstances. This can include natural disasters, carrier demand, disputes, domestic or international customs embargoes, or seasonal shipping volumes.</li>
                    <li>(C) Special or custom orders may require additional lead time. For large items that require Freight shipping a valid phone number is required to call and schedule your delivery.</li>
                </ul>
            </>
        },
        {
            title: "Shipping / Service Costs",
            content: <p>Price quotes for the Services are only estimates. Price quotes are subject to change and shall not under any circumstances be binding. Quotations accepted through our online interface are estimates based on the best customer-provided information available. The final rates and service fees may vary based upon the actual goods tendered, work performed, or other factors such as carrier shipping price changes. Librex Fulfillment specifically disclaims liability for any shipping rate errors due to inaccurate or incomplete information provided to it, such as dimensions and weights.</p>
        },
        {
            title: "International Shipments",
            content: <p>The Customer authorizes Librex Fulfillment to import goods on their behalf. Further, you agree that Librex Fulfillment may delegate the obligation to import the goods on your behalf to a subcontractor. It is your responsibility to reimburse Librex Fulfillment for all applicable taxes &amp; duties for imported goods in addition to the purchase price of the goods and any other applicable costs related to the shipment.</p>
        },
        {
            title: "Verified Shipping Addresses",
            content: <p>Librex shall not be responsible for any fees resulting from incorrect or incomplete information contained in any order (including a mail hold). Librex does not recommend shipping items over 5lbs to PO Boxes. Librex will not be held responsible if an order was non-deliverable at a PO Box.</p>
        },
        {
            title: "Reimburse / Refund Policy",
            content: <p>Librex will cover the shipping cost for any mistake caused on our behalf. Reimbursements are distributed in the form of account credit and cannot be redeemed for cash value.</p>
        },
        {
            title: "Shipping Delays",
            content: <p>During holidays or peak shipping seasons, Customers agree to provide Librex with additional lead time to process orders. During such peak shipping seasons, Customers agree that any guaranteed shipping deadlines or reimbursements during these times will be extended up to 3 business days.</p>
        },
        {
            title: "Damages and Claims",
            content: <p>Any order discrepancies must be reported to the carrier within 24 hours after delivery. All orders leave our warehouse verified in good condition. If products shipped appear to be opened, re-taped, damaged, or tampered with in any way, you must keep the original boxes and file a claim with the shipping carrier. Librex Fulfillment holds no responsibility for credits or claims once parcels have been tendered by the shipping carrier. Librex is not liable to reimburse customers for goods damaged by the shipping carrier. Goods damaged by the carrier will be reimbursed by the carrier responsible.</p>
        },
        {
            title: "Modified or Canceled Orders",
            content: <p>Orders that are canceled or modified after fulfillment has been completed will be subject to a $50 charge to locate the package, void the shipping label, reverse the shipping path and restock the item(s). Orders that are canceled or modified after the package has been collected by the carrier will be subject to a $75 fee to attempt to re-route the shipment back to our facilities or any other address than originally intended. This does not guarantee that the package will be returned to our facilities or delivered to any other address.</p>
        }
    ]
}

const AMAZON_PREP_SECTION = {
    title: "AMAZON PREP / FBA SERVICES",
    content: [
        {
            title: "Amazon Prep Services",
            content: <p>All inbound inventory to Librex Fulfillment’s distribution centers must be compliant with Librex’s receiving process. Please reference section 5.1 for compliant shipment information.</p>
        },
        {
            title: "Storage",
            content: <p>Librex only offers pallet storage options for all FBA prep customers (reference Section 5.6 for pallet compliance rules). Each SKU requires its own separate storage space to ensure an accurate pick &amp; pack process. Pallet storage costs $40 per pallet/month. Storage is not charged for items that leave the warehouse within 7 days of being received.</p>
        },
        {
            title: "Non-Active Inventory",
            content: <p>If there is no activity on your account for 60 consecutive days, Librex will enforce a $25 per SKU charge or issue a termination of this agreement. It is your responsibility to keep inventory active with Librex Fulfillment or notify us about account-related issues. Librex can be notified via email at <a href="mailto:support@librexfulfillment.com">support@librexfulfillment.com</a> or within your LibrexGO portal account. Librex will not refund or credit your accounts if charged for non-active inventory.</p>
        },
        {
            title: "Prep Times and Costs",
            content: <p>All prep costs and times are estimates based on prior records. Any change in volume, labor, or any other factor which might apply during the preparation of your FBA prep request will be charged to you. Payment for prep services must be paid before products leave our distribution centers. Prep turnaround times are around 3 days from the initial request.</p>
        },
        {
            title: "Amazon Ready Shipments",
            content: <p>If your products are shipped to our warehouse in ready-to-ship condition, a box labeling fee will apply per outbound carton/pallet. It is the Customers responsibility to schedule pick-ups with the Amazon Partner carriers and provide the BOL 24 hours prior to pick up. All other outbound shipments are subject to be charged applicable UPS, FEDEX pickup fees.</p>
        },
    ]
}

const PRODUCT_RETURN_SECTION = {
    title: "PRODUCT RETURN",
    content: [
        {
            title: "Return Labels Processing",
            content: <p>To process a return, all Customers must mark a shipped order as “returned” or enter the return information manually on the LibrexGo Dashboard. Once completed, a return identifier label will be generated on the portal. This identifier label must be sent to the customer along with the shipping label to apply to the return package. If this label is not applied to the shipment, Librex is completely unable to receive the return. Each individual return costs $2.50 to process in addition to the shipping costs.</p>
        },
        {
            title: "Return Processing",
            content: <p>Once the package is received at our warehouse facility, Librex shall examine the returned inventory. If the return is deemed “good” the product and its components will be restocked. If the return is deemed “bad” it will be discarded/disposed upon your selected discretion and cannot retrieved.</p>
        },
        {
            title: "Non-Fulfillment Returns",
            content: <p>All non-fulfillment / Amazon return customers must create a return record on the LibrexGo Portal for each return. Returns cannot be processed without proper LibrexGo entries. For each return, a $2.50 fee will be charged to receive, inspect and restock/discard your items.</p>
        }
    ]
}

const TERMINATION_OF_AGREEMENT_SECTION = {
    title: "TERMINATION OF AGREEMENT",
    content: [
        {
            title: "Terms",
            content: <ul>
                <li>(A) You have the right to terminate this Agreement at any time, provided you do not have a balance due. Customers must send Termination requests thirty (30) days before their account closure. The termination starts by sending an email to <a href="mailto:support@librexpress.com">support@librexpress.com</a> or contacting your dedicated support representative.</li>
                <li>(B) Librex Fulfillment may terminate this agreement at its own discretion, without cause, at any time. Once Librex terminates an account, Customers will have thirty (30) days to remove their items from Librex’s distribution centers and make other arrangements.</li>
                <li>(C) The termination of this Agreement shall constitute a termination of any Service Level Agreement or Volume Agreement. Librex Fulfillment reserves the right to immediately modify, suspend or discontinue, temporarily or permanently, the Services (or any part thereof) if you are in breach of this Agreement. All of Your Content (if any) may be permanently deleted by Librex Fulfillment upon any termination of your account in Librex Fulfillment sole discretion. However, all rights to payment shall survive termination or expiration of this Agreement.</li>
            </ul>
        },
        {
            title: "Effects of Termination",
            content: <ul>
                <li>(A) In the event of termination, all outstanding invoices become immediately due.</li>
                <li>(B) The Customer shall promptly reimburse Librex for any reasonable fees incurred for the removal and disposal of their items.</li>
                <li>(C) The Customer agrees to remove all inventory from Librex Fulfillment facilities within 30 days of termination of this Agreement. In the case that your products are not removed promptly, your products will be considered abandoned. Once products become abandoned, Librex will start the removal/disposal process for your items. Disposed items are unrecoverable.</li>
                <li>(D) In the event of termination of this Agreement, Librex Fulfillment agrees to work in good faith with you to assist in your transition to another service partner.</li>
            </ul>
        }
    ]
}

const INTELLECTUAL_PROPERTY_SECTION = {
    title: "INTELLECTUAL PROPERTY RIGHTS",
    content: [
        {
            title: "Librex Fulfillment Property",
            content: <p>For purposes of this Agreement, “Librex Fulfillment Property” shall mean (a) Librex Fulfillment's methodology for the provision of the Services; and (b) Librex Fulfillment's Confidential Information.  Librex Fulfillment hereby retains all worldwide rights, title, and interest in and to the Librex Fulfillment Property. Any rights not expressly granted herein to the Librex Fulfillment Property shall be retained by Librex Fulfillment. The company acknowledges that all rights, title, and interest to the Librex Fulfillment Property is owned by Librex Fulfillment.</p>
        },
        {
            title: "Additional Restrictions",
            content: <p>Other than as permitted herein, the Customer shall not (and shall not permit others), directly or indirectly, to modify, to translate, to decompile, to disassemble, or to reverse engineer any part of the Librex Fulfillment property, or otherwise to attempt to discern the functioning or operation of the website or the Services; or to copy, to rent, to lease, to distribute, or to otherwise transfer any of the rights the Customer receives hereunder.</p>
        },
        {
            title: "Client Property",
            content: <p>No Confidential Information obtained by Librex Fulfillment from Company shall become Librex Fulfillment Property. All materials provided by Company shall be deemed “Client Property” for purposes of this Agreement. Company grants Librex Fulfillment a non-exclusive license to the Client Property solely as needed to provide the Services.</p>
        },
        {
            title: "Data Security",
            content: <p>Software and cloud Services are currently provided from the United States. Registration Information, Account data, information and other data (“Data”) is currently stored and processed in the United States.  Librex Fulfillment has implemented and will maintain appropriate physical, electronic, and managerial procedures intended to protect against the loss, misuse, unauthorized access, alteration or disclosure. These measures include encryption of Data during transmission of the Service and encryption of backups of Data and authentication credentials at rest.  Librex Fulfillment will use commercially reasonable efforts to promptly notify the Customer of any unauthorized Account access to, or use of, Data that comes to Librex Fulfillment's attention. The Customer agrees to immediately notify Librex Fulfillment of any suspected security breach at <a href="mailto:info@librexpress.com">info@librexpress.com</a>, followed by contacting Librex Fulfillment management.</p>
        },
        {
            title: "Third Party Software",
            content: <p>Any third-party software application the Customer uses on the Librex Fulfillment website, to connect to the Services, or related to the Services (“Third Party Software”) is solely subject to any third-party software provider software licenses. Librex Fulfillment does not own, control or have any responsibility or liability for any Third-Party Software.</p>
        },
    ]
}

const INDEMNITY_SECTION = {
    title: "INDEMNITY",
    content: [
        {
            title: "Indemnification",
            content: <>
                <p>Subject to the terms and conditions of this Agreement, Company shall indemnify, defend and hold harmless Librex Fulfillment and its representatives/officers, directors, employees, agents, affiliates, successors and permitted assigns (collectively, “Indemnified Party”) against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys’ fees, fees and the costs of enforcing any right to indemnification under this Agreement and the cost of pursuing any insurance providers, incurred by Indemnified Party or End-User (collectively, “Losses”), arising out or resulting from any claim of a third party alleging:</p>
                <ol>
                    <li>Breach or non-fulfillment of any representation, warranty or covenant under/representation or warranty set forth in this Agreement by Company.</li>
                    <li>Any negligent or more culpable act or omission of Company (including any recklessness or willful misconduct) in connection with the performance of its obligations under this Agreement.</li>
                    <li>Any bodily injury, death of any person or damage to real or tangible personal property caused by the willful or grossly negligent acts or omissions of Company.</li>
                    <li>The acts or omissions (including, without limitation, any negligence or willful misconduct) of any third party whether or not selected by or retained by Librex Fulfillment.</li>
                    <li>Any failure by Company to substantially comply with an applicable Food and Drug Administration (FDA) or other governmental requirement; or any failure by the Customer to comply with any applicable state, federal or international laws.</li>
                </ol>
            </>
        }
    ]
}

const FORCE_MAJEURE_SECTION = {
    title: "FORCE MAJEURE",
    content: [
        {
            title: "Terms",
            content: <p>Librex Fulfillment shall not be liable for any failure or delay in performance hereunder which may be due, in whole or in part, to fire, explosion, earthquake, storm, flood, drought or other adverse weather condition, accident, casualty, breakdown of machinery or facilities, strike, lockout, combination of workmen or other labor difficulties (from whatever cause arising, and whether or not the demands of the employees are reasonable or within  Librex Fulfillment’s power to grant), war, civil disturbance, acts of terrorism, insurrection, riot, act of God or the public enemy, law, act, order, proclamation, decree, regulation, ordinance, instruction or request of Government or other public authorities, judgment or decree of a court of competent jurisdiction, delay or failure of carriers, shippers or contractors, labor shortage or inability to obtain transportation, equipment, operating materials, plant equipment or materials required for our performance, curtailment or suspension of operations to remedy or avoid an actual or alleged violation or violations of Federal, State or local law, as may be in effect from time to time during the Agreement period, or any contingency or delay or failure or cause of any nature beyond the reasonable control of  Librex Fulfillment, whether or not of the kind hereinabove specified and whether or not any such contingency is presently occurring or occurs in the future.  Librex Fulfillment shall give notice of any force majeure event as soon as reasonably practicable by giving notice to your administrative email account.</p>
        }
    ]
}

export const SECTION_CONTENT = [
    TERM_SECTION,
    SERVICES_SECTION,
    PAYMENT_TERMS_SECTION,
    RISK_OF_LOSS_SECTION,
    STORAGE_AND_INVENTORY_SECTION,
    FULFILLMENT_SERVICES_SECTION,
    AMAZON_PREP_SECTION,
    PRODUCT_RETURN_SECTION,
    TERMINATION_OF_AGREEMENT_SECTION,
    INTELLECTUAL_PROPERTY_SECTION,
    INDEMNITY_SECTION,
    FORCE_MAJEURE_SECTION
]