function FeatureSection(props) {

        return (
            <div className='row feature'>
                <div className="col-12 col-lg-6">
                    <p className="h1 text-purple d-none d-lg-block">{props.title}</p>
                    <p>{props.description}</p>
                    <h5>Features</h5>
                    <ul className="feature-list">
                        {props.features.map( (feature, i) => {
                            return <li key={`feature-${i}`}><div className="circle bg-bright-purple text-white me-2">{i+1}</div>{feature}</li>
                        })}
                    </ul>
                </div>
                <div className="col-12 col-lg-6 mb-3 mb-lg-0 d-flex justify-content-center align-items-center"><img src={props.img.src} alt={props.img.alt} className="img-fluid"/></div>
            </div>
        );
}




export default FeatureSection;