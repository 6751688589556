import React, { useEffect } from "react";
import "../assets/scss/_fbaPrep.scss";
import GetAQuoteLink from "../components/reusable/GetAQuoteLink";
import OnboardingContactForm from "../components/reusable/OnboardingContactFom";
import { setTitle, setMetaDescription } from '../utilities';

import HeaderMobile from "../assets/graphics/fbaPreps/header-image--mobile.svg";
import HeaderDesktop from "../assets/graphics/fbaPreps/header-image--desktop.svg";
import TurnaroundTime from "../assets/graphics/fbaPreps/turnaround-time.svg";
import InventoryManagement from "../assets/graphics/fbaPreps/inventory-management.svg";
import WarehouseNetwork from "../assets/graphics/fbaPreps/warehouse-network.svg";
import DedicatedReps from "../assets/graphics/fbaPreps/dedicated-customer-reps.svg";
import ImageTitleText from "../components/ImageTitleText";
import HalfHeightPurpleGradient from "../components/reusable/HalfHeightPurpleGradient";
import NoJumpHeader from "../components/reusable/NoJumpHeader";


const titles = ["FBA", "Amazon Prep Services"];
const description = "Learn how Librex can simplify your FBA prep & replenishment process with one push of a button in our software.";


function FBAPrep(props) {
    useEffect(() => {
        setTitle(titles);
        setMetaDescription(description);
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []);

    const FBAPrep_mobile_hero_aspect_ratio = "calc(267/366*100%)";
    const FBAPrep_desktop_hero_aspect_ratio = "calc(350/1238*100%)";
    return (
        <main className="container-fluid pt-5">
            <div className="container-md">

                <div className="row">
                    <div className="col-12">
                        <h1>Amazon Replenishment<br />in Three Simple Steps</h1>
                        <GetAQuoteLink />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <NoJumpHeader className="d-lg-none" aspect_ratio={FBAPrep_mobile_hero_aspect_ratio} style={{width: "100%"}}>
                            <img src={HeaderMobile} className="img-fluid" alt="FBA Prep services offered by Librex Fulfillment" style={{position: "absolute", top: 0, left: 0}}/>
                        </NoJumpHeader>

                        <NoJumpHeader className="d-none d-lg-block" aspect_ratio={FBAPrep_desktop_hero_aspect_ratio} style={{width: "100%"}}>
                            <img src={HeaderDesktop} className="img-fluid" alt="FBA Prep services offered by Librex Fulfillment" style={{position: "absolute", top: 0, left: 0}}/>
                        </NoJumpHeader>
                    </div>
                    <div className="col-12">
                        <h5 className="mt-5 mb-3" style={{ lineHeight: '24px' }}>How it Happens</h5>
                        <div className="prep-steps">
                            <div className="d-inline-flex flex-column">
                                <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">1</div>
                                <div>
                                    <h3 className="bold">Receive</h3>
                                    <p>Your products are received using our 10-point inspection</p>
                                </div>
                            </div>
                            <div className="d-inline-flex flex-column">
                                <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">2</div>
                                <div>
                                    <h3 className="bold">Store</h3>
                                    <p>We store all your inventory within temperature controlled warehouses</p>
                                </div>
                            </div>
                            <div className="d-inline-flex flex-column">
                                <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">3</div>
                                <div>
                                    <h3 className="bold">Forward</h3>
                                    <p>We prepare your prep request and forward inventory to Amazon</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 col-lg-6">
                        <h1>There's <span className="text-dark-blue">NO<br /> Hidden Fees.</span></h1>
                        <p className="mt-3">We are very transparent about our pricing so we will never surprise you with extra charges. We keep it simple with one payment for each of the three steps.</p>
                        <span className="d-none d-lg-block"><GetAQuoteLink /></span>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3>Receiving</h3>
                        <p>When your items first arrive at the warehouse, we do a <strong>quality control</strong> check of all of the items and count them to make sure that they have all arrived. <strong>95%</strong> of our customers recieve their inventory in <strong>one hour</strong>.</p>
                        <h3>Storage</h3>
                        <p>Storage is charged monthly. Each SKU is stored separately from one another so they can be <strong>accurately picked</strong> by our warehouse team.</p>
                        <h3>Forwarding</h3>
                        <p>Once all of your items are prepared, Librex will send all of your products to Amazons warehouse Network.</p>
                        <span className="d-lg-none"><GetAQuoteLink /></span>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12 prep-features">
                        <ImageTitleText src={TurnaroundTime} alt={"Get 2-3 day turn around times for FBA Preps with Librex Fulfillment"} title="2-3 Day Turn Around" text="Once a Prep Request is created in our software, your items will be prepared and sent to Amazon in 1-2 business days." />
                        <ImageTitleText src={InventoryManagement} alt={"Librex Fulfillment provides cloud-based inventory management software"} title="Inventory Management" text="Access your inventory from your desk or vacation home on our cloud-based software. Monitor your prep requests and inventory as if your products are right there in your living room." />
                        <ImageTitleText src={WarehouseNetwork} alt={"Ensure your orders arrive ontime with Librex Fulfillment's warehouse network"} title="Commercial Grade Warehouse Network" text="Using Librex’s Commercial Grade warehouse network customers are able to reach Amazon’s 185 Fulfillment Centers as quickly as possible." />
                        <ImageTitleText src={DedicatedReps} alt={"Get answers on your with a dedicated Librex Fulfillment representative"} title="Dedicated Customer Representatives" text="Each Librex Customer gets their own personal repersenative to take care of ther needs live 24/7." />
                    </div>
                </div>

            </div>

            <div className="row mt-5 position-relative">
                <div className="col-12">
                    <OnboardingContactForm />
                </div>
                <HalfHeightPurpleGradient />
            </div>
        </main>
    );
}

export default FBAPrep;