const Question = (props) => {
    const HTMLSafeName = props.question.split(' ').map((word) => { return word.toLowerCase() }).join('_').slice(0, -1);
    const heading = `heading_${HTMLSafeName}`;
    const target = `collapse_${HTMLSafeName}`;
    return (
        <div className="accordion-item question">
            <h2 className="accordion-header" id={heading}>
                <button className={`accordion-button ${props.i === 0 && props.open ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${target}`} aria-expanded="true" aria-controls={target}>
                    {props.question}
                </button>
            </h2>
            <div id={target} className={`accordion-collapse collapse ${props.i === 0 && props.open ? 'show': null}`} aria-labelledby={heading}>
                <div className="accordion-body">
                    {props.answer}
                </div>
            </div>
        </div>
    );
}

export default Question;