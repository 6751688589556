// Routing
import { Link } from "react-router-dom";
import LibrexNavLink from "./LibrexNavLink";

// Page links
import { ABOUT_LINK, FBA_PREP_LINK, FULFILLMENT_LINK, GET_STARTED_LINK, HOME_LINK, SOFTWARE_LINK } from "../Constants"


// Graphics
import LibrexLogo from '../assets/icons/logo.svg';
import Facebook from '../assets/icons/facebook.svg';
import FacebookWhite from '../assets/icons/facebookWhite.svg';
import Logo from "./Logo";


// Fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import { sleep } from "../utilities";
import LogoWhite from "./LogoWhite";

const VIEW_ACTIONS = {
    "chat": "zsiq_float",
    "phone": "phoneCallNav"
}

const isXlWindow = () => {
    return window.innerWidth >= 1200;
}

function collapseNav() {
    if (!isXlWindow()) {
        document.getElementById('mainNavCollapse').click();
    }
}

class Nav extends React.Component {
    /**
     * Check if the chat or phone view action were sent as a query param
     */
    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const view_param = urlParams.get("view");

        const view_action = VIEW_ACTIONS[view_param];

        if (view_action) {
            this.clickElement(view_action);
        }
    }

    /**
     * Simulates a click on an element on the page using the provided ID.
     * 
     * @param {string} id - HTML ID of the element to simulate a click on
     */
    async clickElement(id) {
        let el = document.getElementById(id);
        while (!el) {
            await sleep(500)
            el = document.getElementById(id);
        }
        el.click();
    }

    render() {
        return (
            <nav className="navbar fixed-top navbar-expand-xl bg-white shadow-sm">
                <div className="container-xl">

                    <Link className="navbar-brand" to={HOME_LINK}>
                        <Logo src={LibrexLogo} />
                        <span className="ms-2"><strong>Librex</strong> Fulfillment</span>
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span><FontAwesomeIcon icon={faBars} /></span>
                    </button>

                    <div className="collapse navbar-collapse p-3 p-xl-0" id="mainNav">

                        <div className="d-flex d-xl-none justify-content-between align-items-center mb-3">
                            <span><LogoWhite /></span>
                            <button className="navbar-toggler text-white text-end" id="mainNavCollapse" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="19.1602" height="2.05288" rx="1.02644" transform="matrix(0.707107 0.707106 -0.707107 0.707106 1.84009 0.378906)" fill="#F2F2F2" />
                                    <rect width="19.1602" height="2.05288" rx="1.02644" transform="matrix(0.707107 -0.707106 0.707107 0.707106 0.38855 13.9272)" fill="#F2F2F2" />
                                </svg>
                            </button>
                        </div>

                        <div className="d-flex ms-auto">
                            <ul className="navbar-nav me-xl-3">
                                <li className="nav-item">
                                    <LibrexNavLink to={HOME_LINK} onClick={collapseNav} text="Home" />
                                </li>
                                <li className="nav-item">
                                    <LibrexNavLink to={FULFILLMENT_LINK} onClick={collapseNav} text="Fulfillment" />
                                </li>
                                <li className="nav-item">
                                    <LibrexNavLink to={FBA_PREP_LINK} onClick={collapseNav} text="FBA Prep" />
                                </li>
                                <li className="nav-item">
                                    <LibrexNavLink to={SOFTWARE_LINK} onClick={collapseNav} text="Software" />
                                </li>
                                <li className="nav-item">
                                    <LibrexNavLink to={ABOUT_LINK} onClick={collapseNav} text="About" />
                                </li>
                                <li className="nav-item">
                                    <LibrexNavLink to={GET_STARTED_LINK} onClick={collapseNav} text="Get Started" />
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://portal.librexfulfillment.com">Dashboard</a>
                                </li>
                            </ul>
                            <div className="d-flex flex-column justify-content-between">
                                <ul className="navbar-nav d-xl-none">
                                    <li className="nav-item">
                                        <span className="nav-link" role="button" onClick={() => { document.getElementById('zsiq_float').click(); collapseNav() }}>Live Chat</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link" onClick={collapseNav} data-bs-toggle="modal" data-bs-target="#schedulePhoneCallModal" id="phoneCallNav">Schedule a Phone Call</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={GET_STARTED_LINK} onClick={collapseNav}>Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={`${GET_STARTED_LINK}#faq`} onClick={collapseNav}>FAQ</Link>
                                    </li>
                                </ul>

                                <ul className="nav align-items-center">
                                    <a href="http://www.instagram.com/librexfulfillment/" className="me-3 py-2 py-xl-0" target="_blank" rel="noreferrer noopener" >
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.22581 0H24.7742C28.7649 0 32 3.2351 32 7.22581V24.7742C32 28.7649 28.7649 32 24.7742 32H7.22581C3.2351 32 0 28.7649 0 24.7742V7.22581C0 3.2351 3.2351 0 7.22581 0ZM7.22581 2.58065C4.66035 2.58065 2.58065 4.66035 2.58065 7.22581V24.7742C2.58065 27.3396 4.66035 29.4194 7.22581 29.4194H24.7742C27.3396 29.4194 29.4194 27.3396 29.4194 24.7742V7.22581C29.4194 4.66035 27.3396 2.58065 24.7742 2.58065H7.22581Z" fill="currentColor" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.0002 21.161C18.8507 21.161 21.1615 18.8502 21.1615 15.9997C21.1615 13.1492 18.8507 10.8384 16.0002 10.8384C13.1497 10.8384 10.8389 13.1492 10.8389 15.9997C10.8389 18.8502 13.1497 21.161 16.0002 21.161ZM16.0002 23.2255C19.9909 23.2255 23.226 19.9904 23.226 15.9997C23.226 12.009 19.9909 8.77393 16.0002 8.77393C12.0095 8.77393 8.77441 12.009 8.77441 15.9997C8.77441 19.9904 12.0095 23.2255 16.0002 23.2255Z" fill="currentColor" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M24.7745 9.29016C25.9147 9.29016 26.839 8.36585 26.839 7.22565C26.839 6.08545 25.9147 5.16113 24.7745 5.16113C23.6343 5.16113 22.71 6.08545 22.71 7.22565C22.71 8.36585 23.6343 9.29016 24.7745 9.29016Z" fill="currentColor" />
                                        </svg>
                                        <span className="visually-hidden">Connect with us on Instagram</span>
                                    </a>

                                    <a href="https://www.facebook.com/librexofficial/" className="py-2 py-xl-0" target="_blank" rel="noreferrer noopener">
                                        <picture>
                                            <source media="(min-width: 992px)" srcSet={Facebook} />
                                            <source srcSet={FacebookWhite} />
                                            <img src={FacebookWhite} alt="Follow Librex on Facebook" className="img-fluid icon" />
                                        </picture>
                                        <span className="visually-hidden">Connect with us on Facebook</span>
                                    </a>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div id="overlay"></div>
                </div>
            </nav>
        );
    }
}

export default Nav;