import React from "react";

// Sections
import FeatureSection from './FeatureSection';

// Section images
import DashboardScreenshot from '../assets/graphics/featuresWeOffer/dashboardScreenshot.jpg';
import OrdersScreenshot from '../assets/graphics/featuresWeOffer/ordersScreenshot.jpg';
import InboundShipmentScreenshot from '../assets/graphics/featuresWeOffer/inboundShipmentScreenshot.jpg';
import InventoryScreenshot from '../assets/graphics/featuresWeOffer/inventoryScreenshot.jpg';
import FBAPrepScreenshot from '../assets/graphics/featuresWeOffer/FBAPrepScreenshot.jpg';

// Section descriptions, images, and features

const DASHBOARD_DESCRIPTION = "Get a full overview of your eCommerce business in seconds. Preview all of your important store data all in one place.";
const DASHBOARD_FEATURES = ["Full account overview", "Inventory status alerts", "Sales trends"];
const DASHBOARD_IMG = { src: DashboardScreenshot, alt: "Screen captures of the Librex GO Dashboard" };

const ORDERS_DESCRIPTION = "Our support team works around the clock to ensure that all of your orders are being processed in real time. Our Librex Go Dashboard supports seamless integrations with unlimited selling channels.";
const ORDERS_FEATURES = ["Same-day Order Processing", "Real-time Order Reporting", "Error-free Turn Around"];
const ORDERS_IMG = { src: OrdersScreenshot, alt: "Screen captures of the Librex GO Dashboard" };

const INVENTORY_DESCRIPTION = "Managing inventory can become really frustrating especially when you have multiple selling channels. Our in-house software takes all the stress off of having to keep track of your inventory.";
const INVENTORY_FEATURES = ["Live Inventory Updates", "Manage and Transfer Inventory", "Inventory Lifecycle (Coming soon)"];
const INVENTORY_IMG = { src: InventoryScreenshot, alt: "Screen captures of the Librex GO Dashboard" };

const INBOUND_SHIPMENTS_DESCRIPTION = "Send us containers, pallets or individual cartons, and our team will turn around all inbound shipments in less than 8 hours so you can simply start shipping your products the day they arrive.";
const INBOUND_SHIPMENTS_FEATURES = ["Same Day Receiving", "Retail Storage Network keeps your inventory closest to your customers"];
const INBOUND_SHIPMENTS_IMG = { src: InboundShipmentScreenshot, alt: "Screen captures of the Librex GO Dashboard" };

const FBA_REQUESTS_DESCRIPTION = "Keeping up with inventory restrictions from Amazon can be tedious. Whether it’s an FBA prep or replenishment request, we will take care of it within 1 business day. Simply upload your labels to our software and we will take it from there.";
const FBA_REQUESTS_FEATURES = ["Same Day Forwarding", "FBA Prep 2 Day Turnaround", "Custom Work Orders for Bundles and more"];
const FBA_REQUESTS_IMG = { src: FBAPrepScreenshot, alt: "Screen captures of the Librex GO Dashboard" };




class FeaturesWeOffer extends React.Component {

    setCarouselListeners() {
        document.getElementById('sectionCarousel').addEventListener("slid.bs.carousel", () => {

            // get the active slider index
            let activeSlide = 0;
            const slides = document.querySelectorAll('.carousel-item');

            for (let i = 0; i < slides.length; i++) {
                if (slides[i].classList.contains('active')) {
                    activeSlide = i;
                    break;
                }
            }
            // remove the active indicator
            const indicators = document.querySelectorAll('.carousel-indicators li');
            for (let i = 0; i < indicators.length; i++) {
                if (indicators[i].classList.contains('active')) {
                    indicators[i].classList.remove('active');
                }
            }

            // Add active to the new indicator
            const newActive = document.querySelectorAll(`[data-bs-slide-to="${activeSlide}"]`);
            for (let i = 0; i < newActive.length; i++) {
                newActive[i].classList.add('active');
            }

            const title = document.querySelector('.carousel-item.active .h1').innerHTML;
            document.getElementById('activeSection').innerHTML = title;
        });
    }

    render() {
        return (
            <div className="container-fluid bg-white-gradient features-we-offer py-3 py-lg-5">
                <div className="container-xxl">

                    <div id="sectionCarousel" className="carousel slide" data-bs-interval="false" onLoad={this.setCarouselListeners}>

                        <div className="row">
                            <div className="col-12 col-lg-3 pe-lg-5">
                                {!this.props.title ? null : <h5 className="text-lg-end mb-lg-5">{this.props.title}</h5>}
                                <div className="d-none d-lg-flex flex-wrap flex-lg-column justify-content-between justify-content-lg-start align-items-lg-end carousel-indicators h-100">
                                    <div className='sections-link active mb-lg-4' data-bs-target="#sectionCarousel" data-bs-slide-to="0">
                                        Dashboard
                                    </div>

                                    <div className='sections-link mb-lg-4' data-bs-target="#sectionCarousel" data-bs-slide-to="1">
                                        Orders
                                    </div>

                                    <div className='sections-link mb-lg-4' data-bs-target="#sectionCarousel" data-bs-slide-to="2">
                                        Inventory
                                    </div>

                                    <div className="sections-link mb-lg-4" data-bs-target="#sectionCarousel" data-bs-slide-to="3">
                                        Inbound Shipments
                                    </div>

                                    <div className="sections-link mb-lg-4" data-bs-target="#sectionCarousel" data-bs-slide-to="4">
                                        FBA Requests
                                    </div>
                                </div>



                                <div className="d-flex d-lg-none mt-4 mb-2 justify-content-between">
                                    <a href="#sectionCarousel" role="button" data-bs-slide="prev">
                                        <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <path d="M11.175 2L2.99999 10.0675L11.175 17.3282" stroke="#CFDCEA" strokeWidth="4" />
                                        </svg>
                                        <span className="visually-hidden">Previous</span>
                                    </a>

                                    <div id="activeSection" className="sections-link active mx-3 text-start">ORDERS</div>

                                    <a href="#sectionCarousel" role="button" data-bs-slide="next">
                                        <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                                            <path d="M11.175 2L2.99999 10.0675L11.175 17.3282" stroke="#CFDCEA" strokeWidth="4" />
                                        </svg>
                                        <span className="visually-hidden">Next</span>
                                    </a>
                                </div>

                                <ol className="carousel-indicators d-lg-none">
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="0" className="active"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="1"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="2"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="3"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="4"></li>
                                </ol>
                            </div>

                            <div className="col-12 col-lg-9">

                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <FeatureSection title="Dashboard" description={DASHBOARD_DESCRIPTION} features={DASHBOARD_FEATURES} img={DASHBOARD_IMG} />
                                    </div>

                                    <div className="carousel-item">
                                        <FeatureSection title="Orders" description={ORDERS_DESCRIPTION} features={ORDERS_FEATURES} img={ORDERS_IMG} />
                                    </div>

                                    <div className="carousel-item">
                                        <FeatureSection title="Inventory" description={INVENTORY_DESCRIPTION} features={INVENTORY_FEATURES} img={INVENTORY_IMG} />
                                    </div>

                                    <div className="carousel-item">
                                        <FeatureSection title="Inbound Shipments" description={INBOUND_SHIPMENTS_DESCRIPTION} features={INBOUND_SHIPMENTS_FEATURES} img={INBOUND_SHIPMENTS_IMG} />
                                    </div>

                                    <div className="carousel-item">
                                        <FeatureSection title="FBA Requests" description={FBA_REQUESTS_DESCRIPTION} features={FBA_REQUESTS_FEATURES} img={FBA_REQUESTS_IMG} />
                                    </div>
                                </div>

                                <ol className="carousel-indicators d-lg-none justify-content-center">
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="0" className="active"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="1"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="2"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="3"></li>
                                    <li data-bs-target="#sectionCarousel" data-bs-slide-to="4"></li>
                                </ol>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturesWeOffer;