import React from 'react';

import { setTitle, setMetaDescription } from '../utilities';

// Components
import GetAQuoteLink from '../components/reusable/GetAQuoteLink';
import NoJumpHeader from '../components/reusable/NoJumpHeader';

// Graphics
import ThreeEasySteps from "../assets/graphics/services/three-easy-steps.svg";
import EasySetup from "../assets/graphics/services/easy-setup.svg";
import RetailDistributionPower from "../assets/graphics/services/retail-distribution-power.svg";
import DiscountedShipping from "../assets/graphics/services/discounted-shipping.svg";
import OrderAndInventoryManagement from "../assets/graphics/services/order-and-inventory-management.svg";

// Highlight overlays
import HighlightOverlay1 from "../assets/graphics/services/overlays/highlight-overlay-1.svg";
import HighlightOverlay2 from "../assets/graphics/services/overlays/highlight-overlay-2.svg";
import HighlightOverlay3 from "../assets/graphics/services/overlays/highlight-overlay-3.svg";


const titles = ["Services", "What We Offer"];
const description = "Learn more about all the advantages Librex can bring to your business by outsourcing your fulfillment needs. See how our process works with our three step fulfillment process.";

const startingHeight = 414.99;
const startingWidth = 761.02;

const overlay1Left = 90;
const overlay1Top = 35;
const overlay1StartingWidth = 496.2;
const overlay1StartingHeight = 251.26;
const overlay1LeftPercent = overlay1Left / startingWidth;
const overlay1TopPercent = overlay1Top / startingHeight;

const overlay2Left = 170;
const overlay2Top = 50;
const overlay2StartingWidth = 332;
const overlay2StartingHeight = 274.81;
const overlay2LeftPercent = overlay2Left / startingWidth;
const overlay2TopPercent = overlay2Top / startingHeight;

const overlay3Left = 345;
const overlay3Top = 140;
const overlay3StartingWidth = 450.22;
const overlay3StartingHeight = 324.36;
const overlay3LeftPercent = overlay3Left / startingWidth;
const overlay3TopPercent = overlay3Top / startingHeight;

class Services extends React.Component {

    constructor(props) {
        super(props);

        this.graphicRef = React.createRef();

        this.alignHighlight = this.alignHighlight.bind(this);
        this.adjustSize = this.adjustSize.bind(this);
    }

    // Dynamically updates highlight positions so they are always aligned over their corresponding section
    alignHighlight() {
        // Align overlay 1
        document.getElementById('overlay1').style.left = `-${overlay1LeftPercent * this.graphicRef.current.width}px`;
        document.getElementById('overlay1').style.top = `${overlay1TopPercent * this.graphicRef.current.height}px`;

        // Align overlay 2
        document.getElementById('overlay2').style.left = `${overlay2LeftPercent * this.graphicRef.current.width}px`;
        document.getElementById('overlay2').style.top = `${overlay2TopPercent * this.graphicRef.current.height}px`;

        // Align overlay 3
        document.getElementById('overlay3').style.left = `${overlay3LeftPercent * this.graphicRef.current.width}px`;
        document.getElementById('overlay3').style.top = `${overlay3TopPercent * this.graphicRef.current.height}px`;
    }

    // Dynamically updates highlight height and width so they are always cover the correct area
    adjustSize() {
        const heightRatio = this.graphicRef.current.height / startingHeight;
        const widthRatio = this.graphicRef.current.width / startingWidth;

        // Adjust overlay 1
        document.getElementById('overlay1').style.height = `${overlay1StartingHeight * heightRatio}px`;
        document.getElementById('overlay1').style.width = `${overlay1StartingWidth * widthRatio}px`;

        // Adjust overlay 2
        document.getElementById('overlay2').style.height = `${overlay2StartingHeight * heightRatio}px`;
        document.getElementById('overlay2').style.width = `${overlay2StartingWidth * widthRatio}px`;

        // Adjust overlay 3
        document.getElementById('overlay3').style.height = `${overlay3StartingHeight * heightRatio}px`;
        document.getElementById('overlay3').style.width = `${overlay3StartingWidth * widthRatio}px`;
    }

    componentDidMount() {
        // Set page title and meta description
        setTitle(titles);
        setMetaDescription(description);
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        this.graphicRef.current.onload = () => {
            this.alignHighlight();
            this.adjustSize();
        }

        window.onresize = () => {
            this.alignHighlight();
            this.adjustSize();
        }
    }

    componentWillUnmount() {
        // Remove the on-resize function to prevent errors
        window.onresize = () => {

        }
    }

    showOverlay(id) {
        document.getElementById(id).style.display = 'block';
    }

    hideOverlay(id) {
        document.getElementById(id).style.display = 'none';
    }


    render() {
        const fulfillment_hero_aspect_ratio = `calc(${startingHeight}/${startingWidth}*100%)`;
        return (

            <main className="services mb-5 pt-lg-5" id="main" >
                <div className="container-md py-5 position-relative">
                    <div className="row">

                        <div className="col-12 mb-5 d-lg-none">
                            <h1>eCommerce <br className="d-lg-none" />Fulfillment in <br className="d-lg-none" />Three Easy steps</h1>
                            <GetAQuoteLink />
                        </div>

                        <div className="col-12 col-lg-6 col-xxl-7 order-1 order-lg-2 d-flex align-items-center">

                            <NoJumpHeader aspect_ratio={fulfillment_hero_aspect_ratio} style={{ width: "100%" }}>
                                <img src={ThreeEasySteps} className="img-fluid" alt="" ref={this.graphicRef} style={{ position: "absolute", top: 0, left: 0 }} />

                                <img src={HighlightOverlay1} className="img-fluid position-absolute" id="overlay1" alt="" style={{ zIndex: '100', left: overlay1Left, top: overlay1Top, display: 'none' }} />
                                <img src={HighlightOverlay2} className="img-fluid position-absolute" id="overlay2" alt="" style={{ zIndex: '100', left: overlay2Left, top: overlay2Top, display: 'none' }} />
                                <img src={HighlightOverlay3} className="img-fluid position-absolute" id="overlay3" alt="" style={{ zIndex: '100', left: '400px', top: '130px', display: 'none' }} />
                            </NoJumpHeader>
                        </div>
                        <div className="col-12 col-lg-6 col-xxl-5 order-2 order-lg-1 how-it-happens">
                            <div className="d-none d-lg-block">
                                <h1>eCommerce Fulfillment in Three Easy steps</h1>
                                <GetAQuoteLink />
                            </div>

                            <h5 className="mt-5" style={{ lineHeight: '24px' }}>How it Happens</h5>
                            <p className="mt-n3 mb-4"><small>Hover over each section to see the step in action!</small></p>
                            <div className="d-flex flex-row flex-lg-column justify-content-between">
                                <div className="d-inline-flex flex-column flex-lg-row mb-lg-3" onMouseEnter={() => { this.showOverlay('overlay1') }} onMouseLeave={() => { this.hideOverlay('overlay1') }}>
                                    <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">1</div>
                                    <div>
                                        <h3 className="bold">Receive</h3>
                                        <p>Your products arrive at a Librex warehouse</p>
                                    </div>
                                </div>
                                <div className="d-inline-flex flex-column flex-lg-row mb-lg-3" onMouseEnter={() => { this.showOverlay('overlay2') }} onMouseLeave={() => { this.hideOverlay('overlay2') }}>
                                    <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">2</div>
                                    <div>
                                        <h3 className="bold">Store</h3>
                                        <p>Librex stores your items in our temperature controlled facilities</p>
                                    </div>
                                </div>
                                <div className="d-inline-flex flex-column flex-lg-row" onMouseEnter={() => { this.showOverlay('overlay3') }} onMouseLeave={() => { this.hideOverlay('overlay3') }}>
                                    <div className="circle circle-large bg-bright-purple mb-3 me-lg-3">3</div>
                                    <div>
                                        <h3 className="bold">Ship</h3>
                                        <p>Librex packs and ships your orders the same day they're received</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid px-lg-0 py-3 py-lg-5 bg-white-gradient">
                    <div className="container-md">
                        <div className="row">

                            <div className="col-12 col-lg-6 order-1">
                                <h1 className="mb-3">There's <span className="text-blue-gradient">NO</span> <br className="d-lg-none" /><span className="text-cool-blue-gradient">hidden fees.</span></h1>
                                <p>We are very transparent about our pricing so we will never surprise you with extra charges. We keep it simple with <strong>one payment for each of the three steps:</strong></p>
                            </div>
                            <div className="col-12 col-lg-6 text-lg-end order-3 order-lg-2 py-4 py-lg-0">
                                <GetAQuoteLink />
                            </div>
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-between overview mt-3 mt-lg-5 order-2 order-lg-3">
                                <div>
                                    <h3>Receiving</h3>
                                    <p>When your items first arrive at our warehouse, we go by a 10 point checklist to make sure your items arrive as expected. 95% of customers take less than 2 hours to offload their items into our warehouse.</p>
                                </div>
                                <div className="mx-md-3">
                                    <h3>Storage</h3>
                                    <p>Storage is charged monthly. Each SKU is stored separately from one another so they can be accurately picked &amp; packed by our warehouse team.</p>
                                </div>
                                <div>
                                    <h3>Shipping</h3>
                                    <p>Librex offers flat rate shipping up to 10lbs which takes away the complexities of zone-wise shipping. Librex ships domestically and internationally to all 195 countries.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="container-fluid px-lg-0 py-3 py-lg-5 bg-white-gradient section">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <img src={EasySetup} className="img-fluid mb-3 mb-lg-0" alt="" />
                                <div>
                                    <h2>Easy Setup</h2>
                                    <p>Our in-house software enables you to set up your selling channel in 5 minutes or less. This software enables all of your orders to be shipped out automatically.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid px-lg-0 py-3 py-lg-5 bg-white-gradient section">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <div className="order-2 order-md-1">
                                    <h2>Retail Distribution Power</h2>
                                    <p>Librex’s warehouse network allows you to store your products closest to your customers, enabling short transit times. </p>
                                </div>
                                <img src={RetailDistributionPower} className="img-fluid order-1 order-md-2 mb-3 mb-lg-0" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid px-lg-0 py-3 py-lg-5 bg-white-gradient section">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <img src={DiscountedShipping} className="img-fluid mb-3 mb-lg-0" alt="" />
                                <div>
                                    <h2>2-Day Discounted Shipping</h2>
                                    <p>All Librex customers can take advantage of our expedited shipping contracts. Reach your customer in 2-3 days—with no slow or delayed shipments.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid px-lg-0 py-3 py-lg-5 bg-white-gradient section">
                    <div className="container-md">
                        <div className="row">
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-around align-items-center">
                                <div className="order-2 order-md-1">
                                    <h2>Order and Inventory Management</h2>
                                    <p>Access your inventory from your desk or vacation home on our cloud-based software. Monitor your sales and inventory as if your products are right there in your living room.</p>
                                </div>
                                <img src={OrderAndInventoryManagement} className="img-fluid order-1 order-md-2 mb-3 mb-lg-0" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Services