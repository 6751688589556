import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// Components
import Nav from '../components/Nav';
import Footer from '../components/Footer';

//views
import Home from '../views/Home';
import About from '../views/About';
import Software from '../views/Software';
import GetStarted from '../views/GetStarted';
import Services from '../views/Services';
import FBAPrep from "../views/FBAPrep";
import TermsOfService from "../views/TermsOfService";
import { ABOUT_LINK, FBA_PREP_LINK, FULFILLMENT_LINK, GET_STARTED_LINK, HOME_LINK, SOFTWARE_LINK, TERMS_OF_SERVICE_LINK } from "../Constants"

const Website = (props) => {
    return (
        <div className="d-flex flex-column min-vh-100 position-relative">
            <Nav />
            <Routes>
                <Route path={ABOUT_LINK} element={<About />} />
                <Route path={SOFTWARE_LINK} element={<Software />} />
                <Route path={GET_STARTED_LINK} element={<GetStarted />} />
                <Route path={FULFILLMENT_LINK} element={<Services />} />
                <Route path={FBA_PREP_LINK} element={<FBAPrep />} />
                <Route path={TERMS_OF_SERVICE_LINK} element={<TermsOfService />} />
                <Route path={HOME_LINK} element={<Home />} />
                <Route path="/getStarted" element={<Navigate to={GET_STARTED_LINK} />} />
                <Route path="/services" element={<Navigate to={FULFILLMENT_LINK} />} />
            </Routes>
            <Footer />
        </div>
    );
}
export default Website;